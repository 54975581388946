import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from './ui/Button'; 

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Access the selected plan ID and title from the state passed by the PlansPage
  const selectedPlanId = location.state?.selectedPlanId;
  const selectedPlanTitle = location.state?.selectedPlanTitle;

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    if (!selectedPlanId) {
      setError('No plan selected. Please select a plan.');
      return;
    }

    const response = await fetch('https://api.adanthro.ai/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ email, password, name, selectedPlanId }),
    });

    const data = await response.json();

    if (response.ok) {
      // Redirect to the email verification page after successful registration
      navigate('/verify-email', { state: { email } });
    } else {
      setError(data.error || 'An error occurred');
    }
  };

  return (
    <div className="flex h-screen">
      <div className="flex-1 flex items-center justify-center">
        <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-md shadow border border-gray-200">
          <div className="flex justify-end">
            <a href="/login" className="text-gray-500 hover:text-gray-700">Login</a>
          </div>
          <div className="flex justify-center mb-4">
            <a href="/" className="inline-block">
              <img src="/adanthro-logo.png" alt="AdAnthro Logo" className="h-8" />
            </a>
          </div>
          <div className="text-center">
            <h2 className="text-2xl font-semibold text-gray-900">Create an account</h2>
            <p className="text-gray-500">
              Plan: {selectedPlanTitle || (
                <a href="/plans" className="text-red-500 hover:underline">
                  No plan selected. Click here to select a plan.
                </a>
              )}
            </p>
            <p className="text-gray-500">Enter your email below to create your account</p>
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <form className="space-y-6" onSubmit={handleRegister}>
            <div>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <Button type="submit" className="w-full p-3">Continue</Button>
            </div>
          </form>
          <p className="text-center text-sm text-gray-500">
            By clicking continue, you agree to our <a href="#" className="text-gray-700 underline">Terms of Service</a> and <a href="#" className="text-gray-700 underline">Privacy Policy</a>.
          </p>
        </div>
      </div>
      <div className="flex-1 bg-cover bg-center" style={{ backgroundImage: "url('/anthro-bg.jpg')" }}></div>
    </div>
  );
};

export default RegisterPage;
