import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { CirclePlus, ChevronDown, Loader } from 'lucide-react';
import analysisTemplates from './config/analysisTemplates.json';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Header from './Header';
import NavigationBar from './NavigationBar';
import { useAccount } from '../../contexts/AccountContext';
import moment from 'moment-timezone';

const timezones = moment.tz.names();

const advertisingChannelTypeMapping = {
  0: 'UNSPECIFIED',
  1: 'UNKNOWN',
  2: 'SEARCH',
  3: 'DISPLAY',
  4: 'SHOPPING',
  5: 'HOTEL',
  6: 'VIDEO',
  7: 'MULTI_CHANNEL',
  8: 'LOCAL',
  9: 'SMART',
  10: 'PERFORMANCE_MAX',
  11: 'LOCAL_SERVICES',
  12: 'DISCOVERY',
  13: 'TRAVEL'
};

const daysOfWeek = [
  { value: 'Sunday', label: 'Sunday' },
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' }
];

const daysOfMonth = Array.from({ length: 28 }, (_, i) => ({ value: i + 1, label: (i + 1).toString() }));

const AnalysisTemplates = ({ templates, onSelectTemplate, selectedTemplate }) => (
  <div className="analysis-templates space-y-4">
    {Object.keys(templates).map(group => (
      <div key={group} className="template-group">
        <h3 className="font-semibold mb-2">{group}</h3>
        <div className="template-buttons flex flex-wrap">
          {Object.keys(templates[group]).map(templateKey => (
            <button
              type="button"
              key={templateKey}
              className={`template-button p-2 m-1 rounded-md ${selectedTemplate === templateKey ? 'border border-gray-200 shadow-inner shadow-gray-200 text-black ' : 'border border-gray-200 shadow-sm text-gray-600 hover:shadow-md'}`}
              onClick={() => onSelectTemplate(group, templateKey)}
              style={{ whiteSpace: 'nowrap' }}
            >
              {templateKey}
            </button>
          ))}
        </div>
      </div>
    ))}
  </div>
);

const AutomatedReportsPage = () => {
  const [reports, setReports] = useState([]);
  const [newReport, setNewReport] = useState({
    name: '',
    description: '',
    level: 'account',
    campaigns: [],
    metrics: '',
    template: '',
    templateDescription: '',
    companyInfo: '',
    prompts: '',
    dateRange: 'last_day',
    comparisonEnabled: false,
    comparisonRange: '',
    schedule: {
      frequency: 'daily',
      date: '',
      day: '',
      hour: '',
    },
    email: '',
    timezone: moment.tz.guess(),
    queryDetails: {}
  });
  const [campaigns, setCampaigns] = useState([]);
  const [templates, setTemplates] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingReportId, setEditingReportId] = useState(null);
  const [dropdownLoading, setDropdownLoading] = useState(null);
  const [dropdownResponse, setDropdownResponse] = useState(null);
  const [runResponseMessage, setRunResponseMessage] = useState('');
  const dropdownRef = useRef(null);
  const { selectedAccount, setSelectedAccount } = useAccount();

  useEffect(() => {
    fetchAutomatedReports();
    if (selectedAccount && selectedAccount.id) {
      fetchCampaigns(selectedAccount.id, selectedAccount.managerCustomerId);
    }
  }, [selectedAccount]);

  const fetchCampaigns = async (accountId, managerCustomerId) => {
    try {
      const response = await fetch(`https://api.adanthro.ai/api/get-campaigns/${accountId}?managerCustomerId=${managerCustomerId}`, { credentials: 'include' });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setCampaigns(data);
      console.log('Fetched campaigns:', data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const fetchAutomatedReports = async () => {
    if (!selectedAccount || !selectedAccount.id) {
      console.log('No account selected');
      return;
    }
  
    try {
      const response = await fetch(`https://api.adanthro.ai/api/get-automated-reports?accountId=${selectedAccount.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setReports(data);
    } catch (error) {
      console.error('Error fetching automated reports:', error);
    }
  };

  const handleAccountSelect = async (accountId, managerId, accountName) => {
    console.log('Account selected:', { accountId, managerId, accountName });
    setSelectedAccount({ id: accountId, name: accountName, managerCustomerId: managerId });
    fetchCampaigns(accountId, managerId);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewReport((prev) => ({
      ...prev,
      [name]: value,
      queryDetails: {
        ...prev.queryDetails,
        [name]: value
      }
    }));
    setFormErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleScheduleChange = (e) => {
    const { name, value } = e.target;
    setNewReport((prev) => ({ ...prev, schedule: { ...prev.schedule, [name]: value } }));
    setFormErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleCampaignSelect = (selectedOption) => {
    const selectedCampaigns = newReport.level === 'account'
      ? selectedOption.map(option => option.value)
      : [selectedOption.value];

    setNewReport((prev) => ({
      ...prev,
      campaigns: selectedCampaigns,
      template: '',
      templateDescription: '',
      queryDetails: {
        ...prev.queryDetails,
        campaigns: selectedCampaigns
      }
    }));

    const campaign = campaigns.find(c => c.id === selectedCampaigns[0]);
    const campaignType = campaign && advertisingChannelTypeMapping[campaign.type];

    if (newReport.level === 'campaign') {
      if (campaignType && analysisTemplates[campaignType]) {
        setTemplates(analysisTemplates[campaignType]);
      } else {
        setTemplates({});
      }
    } else {
      if (campaignType && analysisTemplates[campaignType]) {
        setTemplates(analysisTemplates[campaignType]);
        const defaultTemplate = "Campaign Performance Analysis";
        const templateDetails = analysisTemplates[campaignType]["Performance Summary"][defaultTemplate];
        setNewReport((prev) => ({
          ...prev,
          template: defaultTemplate,
          templateDescription: templateDetails.description,
          queryDetails: {
            ...prev.queryDetails,
            ...templateDetails
          }
        }));
      } else {
        setTemplates({});
      }
    }

    setFormErrors((prev) => ({ ...prev, campaigns: '' }));
  };

  const handleTemplateSelect = (group, templateKey) => {
    const templateDetails = templates[group][templateKey];
    setNewReport((prev) => ({
      ...prev,
      template: templateKey,
      templateDescription: templateDetails.description,
      queryDetails: {
        ...prev.queryDetails,
        ...templateDetails
      }
    }));
    setFormErrors((prev) => ({ ...prev, template: '' }));
  };

  const handleDateRangeChange = (e) => {
    const { value } = e.target;
    setNewReport((prev) => ({
      ...prev,
      dateRange: value,
      queryDetails: {
        ...prev.queryDetails,
        date_range: getDateRange(value)
      }
    }));
    setFormErrors((prev) => ({ ...prev, dateRange: '' }));
  };

  const handleComparisonRangeChange = (e) => {
    const { value } = e.target;
    setNewReport((prev) => ({
      ...prev,
      comparisonRange: value,
      queryDetails: {
        ...prev.queryDetails,
        comparison_range: getDateRange(value, true, prev.dateRange)
      }
    }));
    setFormErrors((prev) => ({ ...prev, comparisonRange: '' }));
  };

  const handleComparisonToggle = (e) => {
    const { checked } = e.target;
    setNewReport((prev) => ({
      ...prev,
      comparisonEnabled: checked,
      queryDetails: {
        ...prev.queryDetails,
        comparison_range: checked ? getDateRange(prev.comparisonRange) : null
      }
    }));
    setFormErrors((prev) => ({ ...prev, comparisonEnabled: '' }));
  };

  const getDateRange = (range, isComparison = false, mainRange = null) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let startDate, endDate;

    const getDays = (range) => {
      switch (range) {
        case 'last_day':
        case 'previous_day':
          return 1;
        case 'last_week':
        case 'previous_week':
          return 7;
        case 'last_14_days':
        case 'previous_14_days':
          return 14;
        case 'last_28_days':
        case 'previous_28_days':
          return 28;
        case 'last_month':
        case 'previous_month':
          return 30;
        default:
          return 0;
      }
    };

    const days = getDays(range);
    const mainDays = mainRange ? getDays(mainRange) : days;

    if (isComparison) {
      endDate = new Date(today);
      endDate.setDate(endDate.getDate() - mainDays);
      startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - days);
    } else {
      endDate = new Date(today);
      endDate.setDate(endDate.getDate() - 1);
      startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - days + 1);
    }

    return {
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0]
    };
  };

  const handleRun = async (e, reportId) => {
    e.preventDefault();
  
    if (reportId) {
      setDropdownLoading({ ...dropdownLoading, [reportId]: 'running' });
    } else {
      setIsLoading(true);
    }
    try {
      let reportData;
  
      if (reportId) {
        const reportToRun = reports.find(report => report.id === reportId);
        if (!reportToRun) {
          console.error('No report found with the provided ID:', reportId);
          setDropdownResponse({ reportId, message: 'No report found with the provided ID.', success: false });
          return;
        }
        reportData = {
          ...reportToRun,
          queryDetails: {
            ...reportToRun.queryDetails,
            client_id: selectedAccount.id,
            client_name: selectedAccount.name,
            date_range: getDateRange(reportToRun.date_range),
            comparison_range: reportToRun.comparison_enabled ? getDateRange(reportToRun.comparison_range, true, reportToRun.date_range) : null
          }
        };
      } else {
        if (!isFormValid()) {
          setFormErrors(validateForm());
          console.error('Form is not valid:', validateForm());
          return;
        }
        reportData = {
          ...newReport,
          queryDetails: {
            ...newReport.queryDetails,
            client_id: selectedAccount.id,
            client_name: selectedAccount.name,
            date_range: getDateRange(newReport.dateRange),
            comparison_range: newReport.comparisonEnabled ? getDateRange(newReport.comparisonRange, true, newReport.dateRange) : null
          }
        };
      }
  
      console.log('Running Report Data:', reportData);
  
      const response = await fetch('https://api.adanthro.ai/api/run-report', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(reportData),
      });
  
      if (!response.ok) {
        console.error('Network response was not ok:', response.statusText);
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log('Response from backend:', data);
      setRunResponseMessage({ message: data.message, success: true });
    } catch (error) {
      console.error('Error running report:', error);
      setRunResponseMessage({ message: 'Error running report', success: false });
    } finally {
      if (reportId) {
        setDropdownLoading({ ...dropdownLoading, [reportId]: null });
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      setFormErrors(validateForm());
      return;
    }
  
    const updatedReport = {
      ...newReport,
      campaigns: JSON.stringify(newReport.campaigns),
      queryDetails: {
        ...newReport.queryDetails,
        client_id: selectedAccount.id,
        client_name: selectedAccount.name,
        date_range: getDateRange(newReport.dateRange),
        comparison_range: newReport.comparisonEnabled ? getDateRange(newReport.comparisonRange, true, newReport.dateRange) : null
      }
    };
  
    setIsCreating(true);
    setResponseMessage('');
  
    try {
      const url = isEditMode
        ? `https://api.adanthro.ai/api/update-automated-report/${editingReportId}`
        : 'https://api.adanthro.ai/api/save-automated-report';
      const method = isEditMode ? 'PUT' : 'POST';
  
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(updatedReport),
      });
  
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setResponseMessage(data.message);
      fetchAutomatedReports();
      setIsFormOpen(false);
    } catch (error) {
      setResponseMessage('Error saving report');
    } finally {
      setIsCreating(false);
    }
  };

  const handleEdit = async (reportId) => {
    try {
      const response = await fetch(`https://api.adanthro.ai/api/get-automated-report/${reportId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) throw new Error('Network response was not ok');
      const reportToEdit = await response.json();

      let campaigns = [];
      try {
        campaigns = JSON.parse(reportToEdit.campaigns);
      } catch (error) {
        console.error('Error parsing campaigns:', error);
      }

      setNewReport({
        name: reportToEdit.name,
        description: reportToEdit.description,
        level: reportToEdit.level,
        campaigns: campaigns,
        metrics: reportToEdit.metrics,
        template: reportToEdit.template,
        templateDescription: reportToEdit.templateDescription,
        companyInfo: reportToEdit.companyInfo,
        prompts: reportToEdit.prompts,
        dateRange: reportToEdit.dateRange,
        comparisonEnabled: reportToEdit.comparisonEnabled,
        comparisonRange: reportToEdit.comparisonRange,
        schedule: {
          frequency: reportToEdit.scheduleFrequency,
          date: reportToEdit.scheduleDate,
          day: reportToEdit.scheduleDay,
          hour: reportToEdit.scheduleHour,
        },
        email: reportToEdit.email,
        timezone: reportToEdit.timezone, // Populate the timezone field with the existing value
        queryDetails: reportToEdit.queryDetails,
      });

      setEditingReportId(reportId);
      setIsEditMode(true);
      setIsFormOpen(true);
    } catch (error) {
      console.error('Error fetching report details:', error);
    }
  };

  const handleToggleStatus = async (reportId, currentStatus) => {
    setDropdownLoading(reportId);
    setDropdownResponse(null);

    try {
      const response = await fetch(`https://api.adanthro.ai/api/toggle-report-status/${reportId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      console.log(data.message);
      fetchAutomatedReports();
      setDropdownResponse({ reportId, message: data.message, success: true });
    } catch (error) {
      console.error('Error toggling report status:', error);
      setDropdownResponse({ reportId, message: 'Error toggling report status', success: false });
    } finally {
      setDropdownLoading(null);
    }
  };

  const handleDelete = async (reportId) => {
    setDropdownLoading(reportId);
    setDropdownResponse(null);

    try {
      const response = await fetch(`https://api.adanthro.ai/api/delete-report/${reportId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      console.log('Report deleted successfully:', data);
      fetchAutomatedReports();
      setDropdownResponse({ reportId, message: 'Report deleted successfully', success: true });
    } catch (error) {
      console.error('Error deleting report:', error);
      setDropdownResponse({ reportId, message: 'Error deleting report', success: false });
    } finally {
      setDropdownLoading(null);
    }
  };

  const isFormValid = () => {
    const { name, level, campaigns, template, companyInfo, prompts, dateRange, schedule, email } = newReport;
    return (
      name &&
      level &&
      campaigns.length > 0 &&
      template &&
      companyInfo &&
      prompts &&
      dateRange &&
      schedule.frequency &&
      email &&
      (schedule.frequency !== 'once' || (schedule.date && schedule.hour)) &&
      (schedule.frequency !== 'daily' || schedule.hour) &&
      (schedule.frequency !== 'weekly' || (schedule.day && schedule.hour)) &&
      (schedule.frequency !== 'monthly' || (schedule.day && schedule.hour))
    );
  };

  const validateForm = () => {
    const errors = {};
    const { name, level, campaigns, template, companyInfo, prompts, dateRange, schedule, email } = newReport;

    if (!name) errors.name = 'Analysis name is required.';
    if (!level) errors.level = 'Analysis level is required.';
    if (campaigns.length === 0) errors.campaigns = 'At least one campaign must be selected.';
    if (!template) errors.template = 'Analysis template is required.';
    if (!companyInfo) errors.companyInfo = 'Company, Product, Market Information is required.';
    if (!prompts) errors.prompts = 'Questions/Prompts for ChatGPT are required.';
    if (!dateRange) errors.dateRange = 'Analysis Date Range is required.';
    if (!schedule.frequency) errors.scheduleFrequency = 'Schedule frequency is required.';
    if (!email) errors.email = 'Receive Email Address is required.';

    if (schedule.frequency === 'once' && (!schedule.date || !schedule.hour)) {
      errors.scheduleDate = 'Date and time are required for one-time schedules.';
    }

    if (schedule.frequency === 'daily' && !schedule.hour) {
      errors.scheduleHour = 'Hour is required for daily schedules.';
    }

    if (schedule.frequency === 'weekly' && (!schedule.day || !schedule.hour)) {
      errors.scheduleDay = 'Day and hour are required for weekly schedules.';
    }

    if (schedule.frequency === 'monthly' && (!schedule.day || !schedule.hour)) {
      errors.scheduleDay = 'Day and hour are required for monthly schedules.';
    }

    return errors;
  };

  const toggleDropdown = (reportId) => {
    setDropdownOpen(dropdownOpen === reportId ? null : reportId);
    setDropdownResponse(null);
  };

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
    if (!isFormOpen) {
      setNewReport({
        name: '',
        description: '',
        level: 'account',
        campaigns: [],
        metrics: '',
        template: '',
        templateDescription: '',
        companyInfo: '',
        prompts: '',
        dateRange: 'last_day',
        comparisonEnabled: false,
        comparisonRange: '',
        schedule: {
          frequency: 'daily',
          date: '',
          day: '',
          hour: '',
        },
        email: '',
        timezone: moment.tz.guess(),
        queryDetails: {}
      });
      setIsEditMode(false);
      setEditingReportId(null);
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <NavigationBar selectedAccount={selectedAccount} /> {/* Pass selectedAccount to NavigationBar */}
      <div className="flex flex-col flex-grow p-4 overflow-y-auto">
        <Header onSelectAccount={handleAccountSelect} />
        <h2 className="text-2xl font-semibold mb-4">Automated Reports</h2>
        <p className="mb-4">Set up automated reports to receive analysis directly to your email.</p>

        <div className="mb-4 p-4 bg-white rounded-lg shadow border border-gray-200">
          <h3 className="font-semibold mb-2">Existing Automated Reports</h3>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Frequency</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Last Run</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Result</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {reports.map((report) => (
                <tr key={report.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{report.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{report.schedule_frequency}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{report.last_run ? new Date(report.last_run).toLocaleString() : 'Never'}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{report.result || 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{report.status}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="relative">
                      <button onClick={() => toggleDropdown(report.id)} className="flex items-center text-gray-600 hover:text-gray-800">
                        Options
                        <ChevronDown className="ml-2" size={16} />
                      </button>
                      {dropdownOpen === report.id && (
                        <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50">
                          <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={(e) => handleRun(e, report.id)}>
                            {dropdownLoading === report.id ? <Loader className="animate-spin h-5 w-5 mr-2 inline" /> : 'Run'}
                          </button>
                          <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleEdit(report.id)}>
                            {dropdownLoading === report.id ? <Loader className="animate-spin h-5 w-5 mr-2 inline" /> : 'Edit'}
                          </button>
                          <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleToggleStatus(report.id, report.status)}>
                            {dropdownLoading === report.id ? <Loader className="animate-spin h-5 w-5 mr-2 inline" /> : (report.status === 'disabled' ? 'Enable' : 'Disable')}
                          </button>
                          <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleDelete(report.id)}>
                            {dropdownLoading === report.id ? <Loader className="animate-spin h-5 w-5 mr-2 inline" /> : 'Delete'}
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {dropdownResponse && (
            <div className={`mt-2 px-4 py-2 text-sm ${dropdownResponse.success ? 'text-green-600' : 'text-red-600'}`}>
              {dropdownResponse.message}
            </div>
          )}
        </div>

        <button onClick={toggleForm} className="flex items-center text-white bg-black hover:bg-gray-800 p-2 rounded-md mb-4 w-auto">
          <CirclePlus className="mr-2" size={16} />
          Create Report
        </button>

        {isFormOpen && (
          <div className="p-4 bg-white rounded-lg shadow border border-gray-200">
            <h3 className="font-semibold mb-2">{isEditMode ? `Editing report: ${newReport.name}` : 'Create New Automated Report'}</h3>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block font-semibold">Analysis Name</label>
                  <input
                    type="text"
                    name="name"
                    value={newReport.name}
                    onChange={handleChange}
                    className="w-full border rounded p-2"
                  />
                  {formErrors.name && <p className="text-red-600">{formErrors.name}</p>}
                </div>
                <div className="flex-1">
                  <label className="block font-semibold">Description (optional)</label>
                  <textarea
                    name="description"
                    value={newReport.description}
                    onChange={handleChange}
                    className="w-full border rounded p-2"
                  ></textarea>
                </div>
              </div>

              <div className="flex space-x-4 w-1/4">
                <div className="flex-1">
                  <label className="block font-semibold">Choose Level</label>
                  <select
                    name="level"
                    value={newReport.level}
                    onChange={handleChange}
                    className="w-full border rounded p-2"
                  >
                    <option value="account">Account Level</option>
                    <option value="campaign">Campaign Level</option>
                  </select>
                  {formErrors.level && <p className="text-red-600">{formErrors.level}</p>}
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label className="block font-semibold">Choose Campaigns</label>
                  <Select
                    isMulti={newReport.level === 'account'}
                    options={campaigns.map(campaign => ({ value: campaign.id, label: campaign.name }))}
                    onChange={handleCampaignSelect}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={campaigns.filter(c => newReport.campaigns.includes(c.id)).map(c => ({ value: c.id, label: c.name }))}
                  />
                  {formErrors.campaigns && <p className="text-red-600">{formErrors.campaigns}</p>}
                </div>
                {newReport.level === 'account' && (
                  <div className='w-1/2'>
                    <label className="block font-semibold">Metrics</label>
                    <input
                      type="text"
                      name="metrics"
                      value={newReport.metrics}
                      onChange={handleChange}
                      className="w-full border rounded p-2"
                    />
                    {formErrors.metrics && <p className="text-red-600">{formErrors.metrics}</p>}
                  </div>
                )}

                {newReport.level === 'campaign' && (
                  <div className="w-1/2">
                    <label className="block font-semibold">Choose Analysis Template</label>
                    <p className="text-sm mt-2">{newReport.templateDescription}</p>
                    <div className='p-2'>
                      <AnalysisTemplates templates={templates} onSelectTemplate={handleTemplateSelect} selectedTemplate={newReport.template} />
                    </div>
                    {formErrors.template && <p className="text-red-600">{formErrors.template}</p>}
                  </div>
                )}
              </div>

              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label className="block font-semibold">Company, Product, Market Information</label>
                  <textarea
                    name="companyInfo"
                    value={newReport.companyInfo}
                    onChange={handleChange}
                    className="w-full border rounded p-2"
                  ></textarea>
                  {formErrors.companyInfo && <p className="text-red-600">{formErrors.companyInfo}</p>}
                </div>

                <div className="w-1/2">
                  <label className="block font-semibold">Questions/Prompts for ChatGPT</label>
                  <textarea
                    name="prompts"
                    value={newReport.prompts}
                    onChange={handleChange}
                    className="w-full border rounded p-2"
                  ></textarea>
                  {formErrors.prompts && <p className="text-red-600">{formErrors.prompts}</p>}
                </div>
              </div>

              <div className="space-y-2">
                <div className="flex w-1/2">
                  <div className="w-1/2 pr-2">
                    <label className="block font-semibold">Analysis Date Range</label>
                    <select
                      name="dateRange"
                      value={newReport.dateRange}
                      onChange={handleDateRangeChange}
                      className="w-full border rounded p-2"
                    >
                      <option value="last_day">Last day</option>
                      <option value="last_week">Last week</option>
                      <option value="last_14_days">Last 14 days</option>
                      <option value="last_28_days">Last 28 days</option>
                      <option value="last_month">Last month</option>
                    </select>
                    {formErrors.dateRange && <p className="text-red-600">{formErrors.dateRange}</p>}
                  </div>
                  {newReport.comparisonEnabled && (
                    <div className="w-1/2 pl-2">
                      <div className="w-full">
                        <label className="block font-semibold">Comparison Date Range</label>
                        <select
                          name="comparisonRange"
                          value={newReport.comparisonRange}
                          onChange={handleComparisonRangeChange}
                          className="w-full border rounded p-2"
                        >
                          <option value="previous_day">Previous day</option>
                          <option value="previous_week">Previous week</option>
                          <option value="previous_14_days">Previous 14 days</option>
                          <option value="previous_28_days">Previous 28 days</option>
                          <option value="previous_month">Previous month</option>
                        </select>
                        {formErrors.comparisonRange && <p className="text-red-600">{formErrors.comparisonRange}</p>}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex-1 flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="comparisonEnabled"
                    name="comparisonEnabled"
                    checked={newReport.comparisonEnabled}
                    onChange={handleComparisonToggle}
                    className="h-4 w-4"
                  />
                  <label htmlFor="comparisonEnabled" className="font-semibold cursor-pointer">Enable Comparison</label>
                </div>
              </div>

              <div className="flex w-full space-x-4">
                <div className="w-1/3">
                  <label className="block font-semibold">Schedule Settings</label>
                  <select
                    name="frequency"
                    value={newReport.schedule.frequency}
                    onChange={handleScheduleChange}
                    className="w-full border rounded p-2"
                  >
                    <option value="once">Once</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                  {formErrors.scheduleFrequency && <p className="text-red-600">{formErrors.scheduleFrequency}</p>}
                </div>

                {newReport.schedule.frequency === 'weekly' && (
                  <div className="w-1/3">
                    <label className="block font-semibold">Day of the Week</label>
                    <select
                      name="day"
                      onChange={(e) => handleScheduleChange({ target: { name: 'day', value: e.target.value } })}
                      className="w-full border rounded p-2"
                      value={newReport.schedule.day}
                    >
                      {daysOfWeek.map((day) => (
                        <option key={day.value} value={day.value}>
                          {day.label}
                        </option>
                      ))}
                    </select>
                    {formErrors.scheduleDay && <p className="text-red-600">{formErrors.scheduleDay}</p>}
                  </div>
                )}

                {newReport.schedule.frequency === 'monthly' && (
                  <div className="w-1/3">
                    <label className="block font-semibold">Day of the Month</label>
                    <select
                      name="day"
                      onChange={(e) => handleScheduleChange({ target: { name: 'day', value: e.target.value } })}
                      className="w-full border rounded p-2"
                      value={newReport.schedule.day}
                    >
                      {daysOfMonth.map((day) => (
                        <option key={day.value} value={day.value}>
                          {day.label}
                        </option>
                      ))}
                    </select>
                    {formErrors.scheduleDay && <p className="text-red-600">{formErrors.scheduleDay}</p>}
                  </div>
                )}

                {(newReport.schedule.frequency === 'daily' || newReport.schedule.frequency === 'weekly' || newReport.schedule.frequency === 'monthly') && (
                  <div className="w-1/3">
                    <label className="block font-semibold">Hour of the Day</label>
                    <input
                      type="time"
                      name="hour"
                      value={newReport.schedule.hour}
                      onChange={handleScheduleChange}
                      className="w-full border rounded p-2"
                    />
                    {formErrors.scheduleHour && <p className="text-red-600">{formErrors.scheduleHour}</p>}
                  </div>
                )}

                {newReport.schedule.frequency === 'once' && (
                  <div className="flex-1 flex space-x-4">
                    <input
                      type="date"
                      name="date"
                      value={newReport.schedule.date}
                      onChange={handleScheduleChange}
                      className="w-full border rounded p-2"
                    />
                    <input
                      type="time"
                      name="hour"
                      value={newReport.schedule.hour}
                      onChange={handleScheduleChange}
                      className="w-full border rounded p-2"
                    />
                    {formErrors.scheduleDate && <p className="text-red-600">{formErrors.scheduleDate}</p>}
                  </div>
                )}
              </div>

              <div>
                <label className="block font-semibold">Receive Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={newReport.email}
                  onChange={handleChange}
                  className="w-full border rounded p-2"
                />
                {formErrors.email && <p className="text-red-600">{formErrors.email}</p>}
              </div>

              <div>
                <label className="block font-semibold">Timezone</label>
                <select
                  name="timezone"
                  value={newReport.timezone}
                  onChange={handleChange}
                  className="w-full border rounded p-2"
                >
                  {timezones.map((tz) => (
                    <option key={tz} value={tz}>{tz}</option>
                  ))}
                </select>
                {formErrors.timezone && <p className="text-red-600">{formErrors.timezone}</p>}
              </div>

              <div className='flex space-x-4'>
                <button
                  onClick={(e) => handleRun(e, isEditMode ? editingReportId : null)}
                  className="relative flex items-center justify-center text-black bg-white hover:bg-gray-200 border border-black p-2 rounded-md mb-4 w-20"
                >
                  {isLoading ? <Loader className="animate-spin h-5 w-5 mr-2" /> : 'Test'}
                </button>
                <button
                  type="submit"
                  className="relative flex items-center justify-center text-white bg-black hover:bg-gray-800 p-2 rounded-md mb-4 w-40"
                >
                  {isCreating ? <Loader className="animate-spin h-5 w-5 mr-2" /> : 'Confirm'}
                </button>
                {runResponseMessage && <span className="flex items-center">{runResponseMessage.message}</span>}
              </div>
              {runResponseMessage && <div className={`text-sm ${runResponseMessage.success ? 'text-green-600' : 'text-red-600'} mt-2`}>{runResponseMessage.message}</div>}
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutomatedReportsPage;
