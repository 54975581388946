import React from 'react';
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";

export const TermsOfService = () => {
  return (
    <>
    <Navbar />
    <section id="terms-of-service" className="container py-24 sm:py-32 space-y-8">
      <h2 className="text-3xl lg:text-4xl font-bold md:text-center">
        Terms of Service
      </h2>

      <div className="space-y-8">
        <div>
          <h3 className="text-2xl font-bold">Overview of Services</h3>
          <p className="mt-4">
            AdAnthro provides AI-driven insights and recommendations to help users analyze and improve their Google Ads campaigns. Our platform facilitates a bridge between human decision-making and AI-powered analysis, offering users actionable insights and personalized assistance for managing their advertising strategies.
          </p>
        </div>

        <div>
          <h3 className="text-2xl font-bold">User Eligibility</h3>
          <p className="mt-4">
            You must be at least 18 years old to use AdAnthro. By accessing our Services, you confirm that you meet this age requirement and are legally capable of entering into these Terms of Service.
          </p>
        </div>

        <div>
          <h3 className="text-2xl font-bold">Account Registration and Responsibilities</h3>
          <p className="mt-4">
            <strong>Registration:</strong> To access the Services, you must create an account, providing accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.
          </p>
          <p className="mt-4">
            <strong>Account Security:</strong> You agree to notify us immediately of any unauthorized use of your account or breach of security. AdAnthro is not responsible for any losses or damages resulting from your failure to protect your account.
          </p>
        </div>

        <div>
          <h3 className="text-2xl font-bold">Use of Services</h3>
          <p className="mt-4">
            <strong>Permitted Use:</strong> You may use AdAnthro's Services to gain insights, analyze advertising strategies, and interact with AI to optimize your campaigns. The Services are designed to assist with data-driven marketing decisions and improve your advertising results.
          </p>
          <p className="mt-4">
            <strong>Prohibited Use:</strong> You agree not to misuse the Services for any illegal activities, harm, or rights violations, including but not limited to:
          </p>
          <ul className="list-disc pl-5 mt-4">
            <li>Violating advertising platform policies or applicable laws.</li>
            <li>Falsely representing AI-generated content as human-created without proper disclosure.</li>
            <li>Attempting to reverse-engineer or tamper with the underlying technology of the Services.</li>
            <li>Using automated tools or systems to extract data from the platform.</li>
          </ul>
        </div>

        <div>
          <h3 className="text-2xl font-bold">Content and Intellectual Property</h3>
          <p className="mt-4">
            <strong>Your Content:</strong> Any content you provide to AdAnthro, including inputs for analysis and chat interactions, remains your property. You grant AdAnthro a license to use this content solely for improving our Services and providing you with personalized insights.
          </p>
          <p className="mt-4">
            <strong>Ownership of Output:</strong> The insights and content generated by the AI based on your inputs are yours to use. However, as AI may produce similar outputs for different users, ownership is limited to your specific use case.
          </p>
          <p className="mt-4">
            <strong>Accuracy and Limitations:</strong> While AdAnthro strives to provide accurate and useful insights, AI-generated outputs may not always be fully reliable or applicable to every scenario. You are responsible for reviewing and evaluating the recommendations before applying them to your campaigns.
          </p>
        </div>

        <div>
          <h3 className="text-2xl font-bold">Subscription and Payment</h3>
          <p className="mt-4">
            <strong>Billing:</strong> AdAnthro operates on a subscription basis. Subscriptions renew automatically each billing cycle unless canceled.
          </p>
          <p className="mt-4">
            <strong>Subscription Management:</strong> You may manage your subscription, including upgrading or downgrading your plan, through your account settings. Subscription changes take effect in the next billing cycle.
          </p>
          <p className="mt-4">
            <strong>Cancellations:</strong> You may cancel your subscription at any time through your account settings. Cancellations do not qualify for refunds, except where required by law.
          </p>
        </div>

        <div>
          <h3 className="text-2xl font-bold">Termination and Service Discontinuation</h3>
          <p className="mt-4">
            <strong>Termination by You:</strong> You may terminate your account at any time. Once your account is terminated, we may delete all associated data and content.
          </p>
          <p className="mt-4">
            <strong>Termination by Us:</strong> We reserve the right to suspend or terminate your account if we find you in violation of these Terms or legal obligations. We may also terminate accounts that are inactive for extended periods.
          </p>
          <p className="mt-4">
            <strong>Discontinuation of Services:</strong> AdAnthro may discontinue its Services at any time, providing reasonable notice. Users will be refunded for any prepaid, unused portion of their subscription.
          </p>
        </div>

        <div>
          <h3 className="text-2xl font-bold">Disclaimers and Limitations of Liability</h3>
          <p className="mt-4">
            <strong>No Warranties:</strong> AdAnthro provides its Services "as is" without warranties of any kind. We do not guarantee that the platform will meet your expectations or be free from errors or interruptions.
          </p>
          <p className="mt-4">
            <strong>Limitation of Liability:</strong> AdAnthro is not liable for any indirect, incidental, or consequential damages, including loss of profits or data, arising from your use of the Services.
          </p>
        </div>

        <div>
          <h3 className="text-2xl font-bold">Dispute Resolution</h3>
          <p className="mt-4">
            <strong>Governing Law:</strong> These Terms are governed by the laws of Turkey. Any disputes will be resolved under Turkish law.
          </p>
          <p className="mt-4">
            <strong>Arbitration:</strong> Disputes will be resolved through binding arbitration in accordance with recognized arbitration rules.
          </p>
          <p className="mt-4">
            <strong>Class Action Waiver:</strong> You agree to resolve disputes individually and waive your right to participate in class action lawsuits.
          </p>
        </div>

        <div>
          <h3 className="text-2xl font-bold">Contact Information</h3>
          <p className="mt-4">
            For any questions or concerns regarding these Terms, please contact us at <a href="mailto:legal@adanthro.com" className="text-primary hover:underline">legal@adanthro.com</a>.
          </p>
        </div>
      </div>
    </section>
    <Footer />
    </>
  );
};
