import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Button from './ui/Button'; 

const LoginPage = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
        const response = await fetch('https://api.adanthro.ai/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ email, password }),
        });

        const data = await response.json();

        if (response.ok) {
            setIsAuthenticated(true);
            if (data.redirect === '/future-of-marketing') {
                navigate('/future-of-marketing');
            } else {
                navigate('/');
            }
        } else {
            setError(data.error || 'An error occurred');
        }
    } catch (error) {
        setError('Unexpected error occurred');
    }
};

  return (
    <div className="flex h-screen">
      <div className="flex-1 flex items-center justify-center">
        <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-md shadow border border-gray-200">
          <div className="flex justify-end">
          <a href="/register" className="text-gray-500 hover:text-gray-700">Register</a>
          </div>
          <div className="flex justify-center mb-4">
            <a href="/" className="inline-block">
              <img src="/adanthro-logo.png" alt="AdAnthro Logo" className="h-8" />
            </a>
          </div>
          <div className="text-center">
            <h2 className="text-2xl font-semibold text-gray-900">Welcome back!</h2>
            <p className="text-gray-500">Let's get you logged into your AdAnthro account by entering your details below.</p>

          </div>
          {error && <p className="text-red-500">{error}</p>}
          <form className="space-y-6" onSubmit={handleLogin}>
            <div>
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <Button type="submit" className="w-full p-3">Login</Button>
            </div>
          </form>
          <div className="flex justify-between">
            <a href="#!" className="text-sm text-gray-500 hover:underline">Forgot password?</a>
            <a href="/register" className="text-sm text-gray-500 hover:underline">Don't have an account? Register here</a>
          </div>
        </div>
      </div>
      <div className="flex-1 bg-cover bg-center" style={{ backgroundImage: "url('/anthro-bg.jpg')" }}></div>
    </div>
  );
};

export default LoginPage;
