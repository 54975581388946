/* global Paddle */
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Pricing } from '../landing-page/components/Pricing';
import { Navbar } from '../landing-page/components/Navbar';

const PlansPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Determine the context of the page (start, change, or default)
    const isChangePlan = location.state?.from === 'change';
    const isStartPlan = location.state?.from === 'start';
    const planIdToPriceId = {
      2: 'pri_01j8a09bb93sgdqyxg4rypcw82',  // Relax
      3: 'pri_01j7sjjycg1a2f9m1d1ddbpqm7',  // Pro
      4: 'pri_01j6zfhmhc0r8sam2kptkrcyzq',  // Supercharge
  };
    // Load Paddle.js script and initialize it
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            window.Paddle.Environment.set("sandbox");
            window.Paddle.Initialize({
                token: 'test_c43fc901fd4d083d63dff9d97db',
            });
        };

        // Clean up script on unmount
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleStartSubscription = async (plan) => {
      try {
          // Fetch the Paddle customer ID and transaction token using session data
          const response = await fetch('https://api.adanthro.ai/api/get-paddle-customer-id', {
              credentials: 'include'
          });
  
          const data = await response.json();
          const { paddleCustomerId, transactionToken } = data;
  
          if (!paddleCustomerId || !transactionToken) {
              throw new Error('Failed to retrieve Paddle customer ID or transaction token');
          }
  
          const priceId = planIdToPriceId[plan.planId];
  
          if (window.Paddle) {
              // Open Paddle checkout with transaction token as custom data
              window.Paddle.Checkout.open({
                settings: {
                  successUrl: 'https://adanthro.ai/',
              },
                  items: [{ priceId: priceId }], // Mapped priceId
                  customer: {
                      id: paddleCustomerId
                  },
                  customData: JSON.stringify({
                      transaction_token: transactionToken 
                  }),
                  successCallback: (data) => {
                      console.log('Checkout complete:', data);
                      navigate('/'); 

                    },
                  closeCallback: () => {
                      console.log('Checkout closed without purchase.');
                  }
              });
          } else {
              console.error('Paddle is not initialized');
          }
      } catch (error) {
          console.error('Error starting subscription:', error.message || error);
      }
  };
  

  const handlePlanChange = (plan) => {
    console.log(`Change to plan: ${plan.title} (ID: ${plan.planId})`);
  
    fetch('https://api.adanthro.ai/api/change-subscription-plan', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include cookies for session authentication
      body: JSON.stringify({ new_plan_id: plan.planId }),
    })
    .then(response => {
      if (!response.ok) {
        // If response is not OK, throw an error to be caught in the catch block
        return response.json().then(err => { throw err; });
      }
      return response.json();
    })
    .then(data => {
      console.log('Plan changed successfully:', data);
      navigate('/my-plan');
    })
    .catch(error => {
      console.error('Error changing plan:', error.error || error.message || error);
    });
  };

  return (
    <>
      {!isChangePlan && !isStartPlan && <Navbar />}
      {/* If 'start', use handleStartSubscription; if 'change', use handlePlanChange */}
      <Pricing onPlanSelect={isStartPlan ? handleStartSubscription : isChangePlan ? handlePlanChange : undefined} />
    </>
  );
};

export default PlansPage;
