// components/AccountSelector.js
import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { useAccount } from '../../contexts/AccountContext';

const AccountDropdownTree = ({ accounts, onSelectAccount, setIsOpen }) => {
  return (
    <ul className="tree-view pl-4 overflow-y-auto h-64">
      {accounts.map(account => (
        <TreeNode key={account.id} account={account} onSelectAccount={onSelectAccount} setIsOpen={setIsOpen} />
      ))}
    </ul>
  );
};

const TreeNode = ({ account, onSelectAccount, setIsOpen }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandCollapse = () => {
    setExpanded(!expanded);
  };

  const handleAccountSelect = (accountId, managerId, accountName) => {
    onSelectAccount(accountId, managerId, accountName);
    setIsOpen(false); // Close the dropdown
  };

  const isLeafNode = !account.clients || account.clients.length === 0;
  const displayName = account.name || account.id;

  return (
    <li className="flex flex-col">
      <div className="flex items-center space-x-2 cursor-pointer" onClick={() => isLeafNode ? handleAccountSelect(account.id, account.managerId || '', displayName) : handleExpandCollapse()}>
        {account.clients && account.clients.length > 0 ? (
          <>
            <span className="toggle">
              {expanded ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
            </span>
            <span>{displayName}</span>
          </>
        ) : (
          <span>{displayName}</span>
        )}
      </div>
      {expanded && account.clients && account.clients.length > 0 && (
        <ul className="pl-4">
          {account.clients.map(client => (
            <TreeNode key={client.id} account={{ ...client, managerId: account.id }} onSelectAccount={onSelectAccount} setIsOpen={setIsOpen} />
          ))}
        </ul>
      )}
    </li>
  );
};

const AccountSelector = () => {
  const [accounts, setAccounts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { selectedAccount, setSelectedAccount } = useAccount();
  const dropdownRef = useRef(null);

  useEffect(() => {
    fetchAccounts().then(data => {
        setAccounts(data);
        console.log('Fetched accounts:', data);

        if (selectedAccount.id) {
            const matchedAccount = data.find(account => account.id === selectedAccount.id);
            if (matchedAccount) {
                console.log('Matched account:', matchedAccount);
                setSelectedAccount({
                    ...matchedAccount,
                    name: matchedAccount.name || selectedAccount.name, // Preserve name
                    managerCustomerId: matchedAccount.managerCustomerId || selectedAccount.managerCustomerId // Preserve managerCustomerId
                });
            }
        }
    });

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [selectedAccount.id, setSelectedAccount]);


  const fetchAccounts = async () => {
    const response = await fetch('https://api.adanthro.ai/api/get-account-details', { credentials: 'include' });
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json();
  };

  const handleSelectAccount = (accountId, managerId, accountName) => {
    console.log('Account selected:', { accountId, managerId, accountName });
    setSelectedAccount({ id: accountId, name: accountName, managerCustomerId: managerId });
    setIsOpen(false); // Close the dropdown
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="px-4 py-2 flex items-center space-x-2"
      >
        <span>{selectedAccount.name || 'Select Account'}</span>
        <ChevronDown className="h-4 w-4" />
      </button>
      {isOpen && (
        <div className="absolute mt-2 w-64 bg-white border rounded-md shadow-lg z-10">
          <AccountDropdownTree accounts={accounts} onSelectAccount={handleSelectAccount} setIsOpen={setIsOpen} />
        </div>
      )}
    </div>
  );
};

export default AccountSelector;
