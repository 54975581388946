import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import Button from './ui/Button';
import ConfirmationModal from './ConfirmationModal';

const ChatComponent = ({ analysisId, initialMessages, campaignData, comparisonData, tableData, profileData, dataIdentifier }) => {
  const [messages, setMessages] = useState(initialMessages || []);
  const [inputValue, setInputValue] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setMessages(initialMessages || []);
  }, [initialMessages, analysisId]); // Reinitialize messages when initialMessages or analysisId change

  const logData = () => {
    console.log("Analysis ID:", analysisId);
    console.log("Initial Messages:", initialMessages);
    console.log("Campaign Data:", campaignData);
    console.log("Comparison Data:", comparisonData); // Log comparison data
    console.log("Table Data:", tableData);
    console.log("Profile Data:", profileData); // Log profile data for debugging
    console.log("Data Identifier:", dataIdentifier); // Log identifier for debugging
  };

  useEffect(() => {
    logData();
  }, [analysisId, initialMessages, campaignData, comparisonData, tableData, profileData, dataIdentifier]);

  const formatDataForChat = (campaignData, comparisonData, profileData, tableData) => {
    let formattedData = "";

    // Format Campaign Data
    if (campaignData) {
        formattedData += "Campaign Data:\n";
        campaignData.forEach(entry => {
            formattedData += `- Date: ${entry.Date}, Clicks: ${entry.Clicks}, Conversions: ${entry.Conversions}, Cost: ${entry.Cost}, Ctr: ${entry.Ctr}, Impressions: ${entry.Impressions}\n`;
        });
    }

    // Format Comparison Campaign Data
    if (comparisonData) {
        formattedData += "\nComparison Campaign Data:\n";
        comparisonData.forEach(entry => {
            formattedData += `- Date: ${entry.Date}, Clicks: ${entry.Clicks}, Conversions: ${entry.Conversions}, Cost: ${entry.Cost}, Ctr: ${entry.Ctr}, Impressions: ${entry.Impressions}\n`;
        });
    }

    // Format Profile Data
    if (profileData) {
        formattedData += "\nProfile Data:\n";
        Object.entries(profileData).forEach(([key, value]) => {
            formattedData += `- ${key}: ${value}\n`;
        });
    }

    // Format Table Data with Comparison
    if (tableData) {
        formattedData += "\nTable Data:\n";
        const columns = tableData.columns;
        const rows = tableData.rows;
        const comparisonRows = tableData.comparisonRows || [];

        rows.forEach((row, index) => {
            let formattedRow = columns.map((col, i) => `${col}: ${row[i]}`).join(", ");
            let comparisonRow = comparisonRows[index];
            if (comparisonRow) {
                let formattedComparisonRow = columns.map((col, i) => `${col} (Comparison): ${comparisonRow[i]}`).join(", ");
                formattedRow += `\n   Comparison -> ${formattedComparisonRow}`;
            }
            formattedData += `- ${formattedRow}\n`;
        });
    }

    return formattedData;
  };

  const handleSend = async () => {
    if (!inputValue.trim()) return;

    const userMessage = {
      role: 'user',
      content: inputValue,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);

    const conversation = messages.map((msg) => ({
      role: msg.role,
      content: msg.content,
    }));

    conversation.push(userMessage);

    setInputValue('');

    try {
      // Format the data to be appended to the user's message
      const formattedData = formatDataForChat(campaignData, comparisonData, profileData, tableData);
      const combinedMessage = `${inputValue}\n\nHere is the updated data:\n${formattedData}`;

      const response = await axios.post('https://api.adanthro.ai/api/chat', {
        messages: [...conversation, { role: 'user', content: combinedMessage }],
        analysis_id: analysisId,
        data_identifier: dataIdentifier,  // Include identifier
        campaignData,
        comparisonData, // Send comparison data to backend
        tableData,
        profileData
      }, {
        headers: { 'Content-Type': 'application/json' }
      });

      console.log("Data sent to backend:", {
        messages: conversation,
        analysis_id: analysisId,
        data_identifier: dataIdentifier,  // Log identifier for debugging
        campaignData,
        comparisonData, // Log comparison data for debugging
        tableData,
        profileData
      });

      const botMessage = {
        role: 'assistant',
        content: response.data.answer,
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error while sending message to ChatGPT:', error);
    }
  };

  const handleClearChat = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirmClearChat = async () => {
    try {
      const response = await fetch(`https://api.adanthro.ai/api/clear-chat/${analysisId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        setMessages([]);
        setShowConfirmationModal(false);
      } else {
        console.error('Failed to clear chat history');
      }
    } catch (error) {
      console.error('Error while clearing chat history:', error);
    }
  };

  const createMarkup = (markdown) => {
    const html = marked(markdown);
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <div className="flex flex-col h-full p-4 bg-white rounded-lg shadow border border-gray-200 overflow-hidden relative">
      <h2 className="font-semibold mb-2">Chat with your Assistant</h2>
      <button
        onClick={handleClearChat}
        className="absolute top-4 right-4 text-red-500 hover:text-red-700"
      >
        Clear Chat
      </button>
      <div className="flex flex-col flex-grow p-4 bg-white rounded-lg overflow-y-auto">
        {messages.filter(msg => msg.role !== 'system').map((message, index) => ( // Filter out system messages
          <div
            key={index}
            className={`max-w-xs p-2 m-1 rounded-lg shadow ${message.role === 'user' ? 'self-end bg-blue-100' : 'self-start bg-gray-100'}`}
            dangerouslySetInnerHTML={createMarkup(message.content)}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="flex items-center p-4">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSend()}
          placeholder="Type your message here..."
          className="flex-grow p-2 border rounded-md shadow"
        />
        <Button
          onClick={handleSend}
          className="ml-2 rounded-md"
          style={{ minWidth: '100px', height: '40px' }}
        >
          Send
        </Button>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          title="Clear Chat"
          message="Are you sure you want to clear the chat history? This action cannot be undone."
          confirmButtonText="Yes, Clear"
          onClose={() => setShowConfirmationModal(false)}
          onConfirm={handleConfirmClearChat}
        />
      )}
    </div>
  );
};

export default ChatComponent;
