import React, { useEffect } from 'react';

function EyeAnimation({ height, width }) {
  useEffect(() => {
    function playAnimation() {
      const animations = document.querySelectorAll('#eyeSvg animate, #eyeSvg animateTransform');
      animations.forEach(anim => {
        anim.beginElement();
      });

      // Repeat the animation every 1.7 seconds (matching the total duration)
      setTimeout(playAnimation, 1700);
    }

    playAnimation(); // Start the animation when the component mounts

    return () => {
      // Clean up timeout if the component unmounts
      clearTimeout(playAnimation);
    };
  }, []);

  return (
    <svg
      id="eyeSvg"
      width={width}
      height={height}
      viewBox="0 0 24 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      {/* Stretched Shape */}
      <path
        fill="#000000"
        d="M9.166.33a2.25 2.25 0 00-2.332 0l-5.25 3.182A2.25 2.25 0 00.5 5.436v5.128a2.25 2.25 0 001.084 1.924l5.25 3.182a2.25 2.25 0 002.332 0l5.25-3.182a2.25 2.25 0 001.084-1.924V5.436a2.25 2.25 0 00-1.084-1.924L9.166.33z"
        transform="scale(1.5, 1)"
      />

      {/* Outer Glow Effect */}
      <defs>
        <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur stdDeviation="0.8" result="coloredBlur" />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>

      {/* Thicker Ring with Minimal Outer Movement */}
      <ellipse cx="12" cy="8" rx="6" ry="6" fill="none" stroke="white" strokeWidth="1" filter="url(#glow)">
        {/* Subtle Movement to Left with Minimal Outer Shift */}
        <animateTransform
          attributeName="transform"
          type="translate"
          from="0,0"
          to="-2,0"
          dur="0.3s"
          begin="0s"
          fill="freeze"
        />
        <animate
          attributeName="rx"
          from="6"
          to="5.5"
          dur="0.3s"
          begin="0s"
          fill="freeze"
        />

        {/* Movement Back to Center */}
        <animateTransform
          attributeName="transform"
          type="translate"
          from="-2,0"
          to="0,0"
          dur="0.2s"
          begin="0.5s"
          fill="freeze"
        />
        <animate
          attributeName="rx"
          from="5.5"
          to="6"
          dur="0.2s"
          begin="0.5s"
          fill="freeze"
        />

        {/* Subtle Movement to Right with Minimal Outer Shift */}
        <animateTransform
          attributeName="transform"
          type="translate"
          from="0,0"
          to="2,0"
          dur="0.3s"
          begin="1s"
          fill="freeze"
        />
        <animate
          attributeName="rx"
          from="6"
          to="5.5"
          dur="0.3s"
          begin="1s"
          fill="freeze"
        />

        {/* Movement Back to Center */}
        <animateTransform
          attributeName="transform"
          type="translate"
          from="2,0"
          to="0,0"
          dur="0.2s"
          begin="1.5s"
          fill="freeze"
        />
        <animate
          attributeName="rx"
          from="5.5"
          to="6"
          dur="0.2s"
          begin="1.5s"
          fill="freeze"
        />
      </ellipse>
    </svg>
  );
}

export default EyeAnimation;
