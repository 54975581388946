import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from './ui/Button';

const VerifyEmailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || "your email";

  const handleResendEmail = () => {
    // Logic to resend the email
    console.log("Resend email to:", email);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 py-12">
      <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-200 max-w-md w-full text-center">
        <div className="mb-6">
          <img src="/adanthro-logo.png" alt="Logo" className="mx-auto h-12" />
        </div>
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Please verify your email</h2>
        <p className="text-gray-700 mb-6">
          You're almost there! We sent an email to <strong>{email}</strong>. Just click on the link in that email to complete your signup. If you don't see it, you may need to check your spam folder.
        </p>
        <Button onClick={handleResendEmail}>
          Resend Email
        </Button>
        <p className="text-gray-500 mt-4">
          Still can't find the email? <a href="mailto:hello@adanthro.ai" className="text-blue-500 underline">Contact Us</a>
        </p>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
