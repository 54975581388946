import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { SelectIcon, ChatIcon, MagicOptimizeIcon } from './Icons';
import gAdsIcon from "../assets/gads.png";
// Define the features array without TypeScript types
const features = [
  {
    icon: <img src={gAdsIcon} alt="Google Ads Icon" width="60px" height="60px" />,
    title: 'Connect Your Google Ads Account',
    description:
      'Effortlessly link your Google Ads account to our platform. We ensure a seamless integration, allowing you to pull in all relevant campaign data with just a few clicks.',
  },
  {
    icon: <SelectIcon />,
    title: 'Select Your Campaigns',
    description:
      'Choose the campaigns you want to analyze. Our intuitive interface makes it easy to select and download campaigns.',
  },
  {
    icon: <ChatIcon />,
    title: 'Chat With Your Assistant',
    description:
      'Our AI-powered assistant analyzes your campaign data to provide actionable insights. Discover what’s working, identify areas for improvement, and optimize your strategies.',
  },
  {
    icon: <MagicOptimizeIcon />,
    title: 'Optimize with AI Suggestions',
    description:
      'Receive AI-driven recommendations tailored to your specific campaigns. Implement these suggestions to enhance your ad performance and achieve better results.',
  },
];

// Convert to a functional React component
export const HowItWorks = () => {
  return (
    <section
      id="howItWorks"
      className="container text-center py-24 sm:py-32"
    >
      <h2 className="text-3xl md:text-4xl font-bold">
        Step-by-Step Guide
      </h2>
      <p className="md:w-3/4 mx-auto mt-4 mb-8 text-xl text-muted-foreground">
        You will be surprised how easy it is to use our platform.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {features.map(({ icon, title, description }) => (
          <Card
            key={title}
            className="bg-muted/50"
          >
            <CardHeader>
              <CardTitle className="grid gap-4 place-items-center">
                {icon}
                {title}
              </CardTitle>
            </CardHeader>
            <CardContent>{description}</CardContent>
          </Card>
        ))}
      </div>
    </section>
  );
};
