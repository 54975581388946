import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#845EC2', '#D65DB1', '#FF6F91', '#FF9671', '#FFC75F', '#F9F871'];

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChartVisualization = ({ data, currencyCode }) => {
  const totalSpend = data.reduce((acc, item) => acc + item.spend, 0);

  return (
    <div className="p-4 bg-white rounded-lg shadow h-full border border-gray-200 overflow-hidden">
      <h2 className="font-semibold mb-2">Campaign Spend Distribution</h2>
      <div className="flex">
        <div className="w-2/3">
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                dataKey="spend"
                isAnimationActive={false}
                data={data}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                labelLine={false}
                label={renderCustomizedLabel}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const { campaign, spend } = payload[0].payload;
                    const percentage = ((spend / totalSpend) * 100).toFixed(2);
                    return (
                      <div className="bg-white p-2 rounded shadow">
                        <p>{campaign}</p>
                        <p>{spend.toLocaleString(undefined, { style: 'currency', currency: currencyCode })}</p>
                        <p>{percentage}%</p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="w-1/3 flex flex-col justify-center ml-4">
          {data.map((entry, index) => (
            <div key={`legend-${index}`} className="flex items-center mb-2">
              <span
                className="block w-4 h-4 mr-2 rounded-full"
                style={{ backgroundColor: COLORS[index % COLORS.length] }}
              ></span>
              <span className="truncate w-32" title={entry.campaign}>{entry.campaign}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PieChartVisualization;
