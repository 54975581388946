import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import TableVisualization from './TableVisualization';
import CampaignDataVisualization from './CampaignDataVisualization';
import ChatComponent from './ChatComponent';
import CampaignProfile from './CampaignProfile';
import CampaignProfileModal from './CampaignProfileModal';
import NavigationBar from './NavigationBar';
import DatePickerComponent from './DatePickerComponent';
import { format, subDays } from 'date-fns';
import { useAccount } from '../../contexts/AccountContext';
import EyeAnimation from './ui/EyeAnimation';

const AnalysisPage = () => {
  const { id } = useParams();

  const { selectedAccount } = useAccount();

  const [analysisName, setAnalysisName] = useState('');
  const [campaignDetails, setCampaignDetails] = useState('');
  const [campaignData, setCampaignData] = useState(null);
  const [comparisonData, setComparisonData] = useState(null);
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [comparisonRows, setComparisonRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const [profileData, setProfileData] = useState({
    companyName: '',
    businessGoals: '',
    primaryObjectives: '',
    targetAudience: '',
    audienceDetails: '',
    competitorInfo: '',
    competitors: '',
    businessInfo: '',
    companyDetails: ''
  });
  const [dataIdentifier, setDataIdentifier] = useState(Date.now().toString()); // Unique identifier

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [datePickerPosition, setDatePickerPosition] = useState({ top: 0, left: 0 });
  const [selectedRange, setSelectedRange] = useState({
    startDate: subDays(new Date(), 28),
    endDate: new Date(),
  });
  const [comparisonRange, setComparisonRange] = useState({
    startDate: null,
    endDate: null,
  });
  const dateButtonRef = useRef(null);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const fetchAnalysisData = async (startDate, endDate, compareStartDate, compareEndDate) => {
    try {
      const response = await fetch(`https://api.adanthro.ai/api/get-analysis-data/${id}?start_date=${startDate}&end_date=${endDate}&compare_start_date=${compareStartDate}&compare_end_date=${compareEndDate}`, {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCampaignData(data.campaignData);
      setComparisonData(data.comparisonData);
      setTableData({ columns: data.columns, rows: data.rows });
      setComparisonRows(data.comparisonRows || []);
      setAnalysisName(data.analysisName);
      setCampaignDetails(data.campaignName);
      setChatHistory(data.chatHistory || []);
      setProfileData(data.campaignProfile || {});
    } catch (error) {
      console.error('Error fetching analysis data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalysisData(
      format(selectedRange.startDate, 'yyyy-MM-dd'),
      format(selectedRange.endDate, 'yyyy-MM-dd'),
      comparisonRange.startDate ? format(comparisonRange.startDate, 'yyyy-MM-dd') : '',
      comparisonRange.endDate ? format(comparisonRange.endDate, 'yyyy-MM-dd') : ''
    );
  }, [id, selectedRange, comparisonRange]);

  const handleApplyDateRange = (range) => {
    setSelectedRange({
      startDate: range.selection.startDate,
      endDate: range.selection.endDate
    });
    setComparisonRange({
      startDate: range.compare.startDate,
      endDate: range.compare.endDate
    });
    setDataIdentifier(Date.now().toString());  // Update identifier
    setShowDatePicker(false);
  };

  const handleOpenDatePicker = () => {
    const buttonRect = dateButtonRef.current.getBoundingClientRect();
    setDatePickerPosition({
      top: buttonRect.bottom + window.scrollY,
      left: buttonRect.left + window.scrollX
    });
    setShowDatePicker(true);
  };

  const handleOpenProfileModal = () => {
    setShowProfileModal(true);
  };

  const handleCloseProfileModal = () => {
    setShowProfileModal(false);
  };

  const handleSaveProfileData = async (data) => {
    const descriptiveProfileData = {
      'companyName': data.companyName,
      'businessGoals': data.businessGoals,
      'primaryObjectives': data.primaryObjectives,
      'targetAudience': data.targetAudience,
      'audienceDetails': data.audienceDetails,
      'competitorInfo': data.competitorInfo,
      'competitors': data.competitors,
      'businessInfo': data.businessInfo,
      'companyDetails': data.companyDetails
    };
    try {
      const response = await fetch(`https://api.adanthro.ai/api/save-campaign-profile/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(descriptiveProfileData)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setProfileData(data);
      setDataIdentifier(Date.now().toString());  // Update identifier
      setShowProfileModal(false);
    } catch (error) {
      console.error('Error saving campaign profile data:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <EyeAnimation height={200} width={200} />
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <NavigationBar selectedAccount={selectedAccount} /> {/* Pass selectedAccount to NavigationBar */}
      <div className="flex-grow flex flex-col h-full p-2">
        <Header />
        <div className="flex items-center m-2">
          <h1 className="text-xl font-semibold mr-2 self-end">{analysisName}</h1>
          <p className="self-end">{campaignDetails}</p>
          <button
            ref={dateButtonRef}
            className="ml-4 border-b border-gray-400 hover:bg-gray-100 self-end"
            onClick={handleOpenDatePicker}
          >
            {format(selectedRange.startDate, 'MM/dd/yyyy')} - {format(selectedRange.endDate, 'MM/dd/yyyy')}
            {comparisonRange.startDate && comparisonRange.endDate && (
              <> (Comparing: {format(comparisonRange.startDate, 'MM/dd/yyyy')} - {format(comparisonRange.endDate, 'MM/dd/yyyy')})</>
            )}
          </button>
        </div>

        {showDatePicker && (
          <DatePickerComponent
            onApply={handleApplyDateRange}
            onClose={() => setShowDatePicker(false)}
            position={datePickerPosition}
            selectedRange={selectedRange}
            comparisonRange={comparisonRange}
          />
        )}
        <div className="flex flex-grow overflow-hidden">
          <aside className="flex flex-col w-3/5 space-y-4 m-2">
            <div className="flex flex-col h-1/2 flex-grow">
              <CampaignDataVisualization data={campaignData} comparisonData={comparisonData} />
            </div>
            <div className="flex flex-col h-1/2 flex-grow">
              <TableVisualization data={tableData} comparisonData={comparisonRows} />
            </div>
          </aside>
          <aside className="flex flex-col w-2/5 space-y-4 m-2">
            <div className="flex flex-col h-1/4">
              <CampaignProfile data={profileData} onViewAll={handleOpenProfileModal} />
            </div>
            <div className="flex-grow flex flex-col overflow-hidden">
              <ChatComponent
                analysisId={id}
                initialMessages={chatHistory}
                campaignData={campaignData}
                comparisonData={comparisonData} 
                tableData={{
                  columns: tableData.columns,
                  rows: tableData.rows,
                  comparisonRows, 
                  dateRange: selectedRange,
                  comparisonRange
                }}
                profileData={profileData}
                dataIdentifier={dataIdentifier}
              />

            </div>
          </aside>
        </div>
      </div>
      {showProfileModal && (
        <CampaignProfileModal
          data={profileData}
          onClose={handleCloseProfileModal}
          onSave={handleSaveProfileData}
        />
      )}
    </div>
  );
};

export default AnalysisPage;
