import React from 'react';

const tipsList = [
  "Start with a Campaign Profile: Before diving into data analysis, fill out your campaign profile with details about your business goals and target audience. This foundational step helps tailor the insights you receive, making them more relevant and actionable.",
  
  "Keyword Research and Selection: Identify high-performing keywords tailored to your audience. Leverage the ChatGPT feature to generate and refine keyword lists, and use the analysis tools to evaluate their effectiveness over time.",
  
  "Ad Copy Creation: Craft compelling ad copies that resonate with your target demographic. Chat with ChatGPT to draft and polish ad copy that aligns with your brand voice and target audience.",
  
  "Ad Extensions Utilization: Enhance ads with useful extensions to improve visibility and click-through rates. Use the app to track the performance of different extensions and consult with ChatGPT for suggestions on which extensions to use.",
  
  "Budget Management: Set and adjust budgets to maximize return on investment. Monitor your budget performance using the app’s automated reports, and chat with ChatGPT for insights on optimizing your spending.",
  
  "Bidding Strategy Optimization: Continuously refine your bidding strategies to stay competitive in ad auctions. Use the app's analysis features to track bidding performance and consult ChatGPT for recommendations on adjusting your strategy.",
  
  "Performance Monitoring: Track campaign performance against key metrics like clicks, conversions, and ROI. Use the automated reporting feature to receive regular updates, and use ChatGPT to analyze trends and performance metrics.",
  
  "A/B Testing: Systematically test different elements of your campaigns to find what works best. Chat with ChatGPT to design A/B tests, and analyze results using the app's visualization tools to make data-driven decisions.",
  
  "Regular Campaign Updates and Refreshes: Keep your campaigns up-to-date with the latest market trends and seasonal opportunities. Schedule automated reports to keep track of performance and chat with ChatGPT to brainstorm new campaign ideas.",
  
  "In-depth Analysis and Reporting: Deep dive into campaign analytics to understand detailed performance dynamics. Use the app's chat feature to connect with ChatGPT to explore and interpret complex data.",
  
  "Competitor Analysis: Monitor competitor strategies to identify opportunities and threats in your market. Use ChatGPT to help you gather insights on competitor activities, and analyze your performance relative to competitors using the app's analysis features.",
  
  "Landing Page Optimization: Ensure your landing pages are optimized for conversions to maximize the effectiveness of your ads. Chat with ChatGPT for suggestions on improving landing page content, and track performance using the app's analytics.",
  
  "Target Audience Refinement: Regularly refine and update your target audience to ensure you are reaching the right people. Use the app’s audience insights and ChatGPT to identify new audience segments or refine existing ones.",
  
  "Geo-targeting: Use geo-targeting to focus your ads on specific locations where your target audience is most active. Analyze performance by region using automated reports and adjust your strategy with input from ChatGPT.",
  
  "Device Targeting: Optimize your campaigns by targeting specific devices based on your audience's behavior. Use the app's device performance reports to see how your ads perform across different devices, and chat with ChatGPT for advice on targeting.",
  
  "Remarketing Campaigns: Use remarketing to re-engage visitors who have previously interacted with your site. Plan and refine your remarketing strategies with ChatGPT and track performance through the app's automated reports.",
  
  "Ad Scheduling: Schedule your ads to appear at times when your target audience is most likely to be online. Use the app’s reporting features to analyze peak engagement times and consult with ChatGPT to optimize your ad scheduling.",
  
  "Custom Audiences: Create custom audiences to target specific segments of your customer base. Use ChatGPT to brainstorm and define custom audiences, and monitor their performance using the app's audience segmentation tools.",
  
  "Negative Keywords: Use negative keywords to exclude search terms that are not relevant to your campaign. Chat with ChatGPT to identify potential negative keywords and refine your targeting strategy.",
  
  "Leveraging Seasonal Trends: Adjust your campaigns to align with seasonal trends and capitalize on heightened consumer interest. Use the app's automated reports to track seasonal performance and chat with ChatGPT to brainstorm campaign adjustments."
];

function getRandomTips(tips, count) {
    const shuffled = tips.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
}

const TipsSection = () => {
    const tipsToShow = getRandomTips(tipsList, 3);

    return (
        <div className="bg-white rounded-lg shadow border border-gray-200 p-4">
            <h2 className="font-semibold mb-2">Tips</h2>
            <ul>
                {tipsToShow.map((tip, index) => (
                    <li key={index} className="mb-2">{tip}</li>
                ))}
            </ul>
        </div>
    );
};

export default TipsSection;
