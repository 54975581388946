import React from 'react';
import { Edit, Eye } from 'lucide-react';

const CampaignProfile = ({ data, onViewAll }) => {
    return (
        <div className="p-4 bg-white rounded-lg shadow border border-gray-200 h-full">
            <div className="space-y-2">
                <div className="flex justify-between items-center">
                    <span className="flex-1">
                        <span className="font-medium">Company Name: </span> {data.companyName}
                    </span>
                    <button onClick={onViewAll} className="flex items-center p-1 text-gray-500">
                        <Eye className="h-4 w-4 mr-1" />
                        View All
                    </button>
                </div>
                <div className="flex justify-between items-center">
                    <span className="flex-1">
                        <span className="font-medium">Business Goals: </span> {data.businessGoals}
                    </span>
                    <button onClick={onViewAll} className="flex items-center p-1 text-gray-500">
                        <Edit className="h-4 w-4 mr-1" />
                        Edit
                    </button>
                </div>
                <div className="flex justify-between items-center">
                    <span className="flex-1">
                        <span className="font-medium">Target Audience: </span> {data.targetAudience}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CampaignProfile;
