import React from 'react';
import { Navbar } from './Navbar'; // Assuming you have a Navbar component
import { Footer } from './Footer'; // Assuming you have a Footer component

export const Blog = () => {
  return (
    <>
      <Navbar />
      <section className="container mx-auto flex flex-col items-center justify-center py-24 sm:py-32 space-y-6 text-center">
        <h1 className="text-4xl lg:text-6xl font-bold mb-4">Blog Coming Soon</h1>
        <p className="text-lg lg:text-xl opacity-75 max-w-xl">
          We're working hard to bring you valuable insights, tips, and updates to help you maximize your marketing efforts with AdAnthro.
        </p>
        <p className="text-md lg:text-lg opacity-60 max-w-xl">
          Stay tuned for exciting content about AI-powered ad strategies, campaign optimization, and more. Check back soon!
        </p>
        <div className="mt-8">
          <a
            href="/"
            className="text-primary hover:underline font-semibold text-lg"
          >
            Back to Home
          </a>
        </div>
      </section>
      <Footer />
    </>
  );
};
