import React, { useState, useEffect, useRef } from 'react';
import { X } from 'lucide-react';

const CampaignProfileModal = ({ data, onClose, onSave }) => {
    const [formData, setFormData] = useState(data);
    const modalRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSave = () => {
        onSave(formData);
    };

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div 
                ref={modalRef} 
                className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full max-h-[80vh] overflow-auto"
            >
                <div className="flex justify-between items-center">
                    <h2 className="text-xl font-bold mb-4">Edit Campaign Profile</h2>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
                        <X className="w-6 h-6" />
                    </button>
                </div>
                <p className="text-gray-700 mb-6">
                    The AI uses the details you provide here to conduct a tailored analysis of your campaign. By understanding your business context, goals, target audience, and competitive landscape, the AI can deliver insights and recommendations that are directly relevant to your strategic objectives. Please provide as much detail as possible to enable the most comprehensive analysis.
                </p>
                <div className="space-y-6">
                    <div className="p-4 bg-gray-50 shadow rounded-lg">
                        <h3 className="text-lg font-semibold mt-4">Business Information</h3>
                        <div className="mt-2">
                            <label className="block font-medium">1- What is your company name?</label>
                            <textarea 
                                name="companyName" 
                                value={formData.companyName} 
                                onChange={handleChange} 
                                className="w-full p-2 border rounded-md" 
                            />
                        </div>
                        <div className="mt-2">
                            <label className="block font-medium">2- Can you provide a brief description of your company?</label>
                            <textarea 
                                name="companyDetails" 
                                value={formData.companyDetails} 
                                onChange={handleChange} 
                                className="w-full p-2 border rounded-md" 
                            />
                        </div>
                    </div>

                    <div className="p-4 bg-gray-50 shadow rounded-lg">
                        <h3 className="text-lg font-semibold mt-4">Business Goals</h3>
                        <div className="mt-2">
                            <label className="block font-medium">1- What are your main goals with your Google Ads campaigns? (e.g., brand awareness, lead generation, sales)</label>
                            <textarea 
                                name="businessGoals" 
                                value={formData.businessGoals} 
                                onChange={handleChange} 
                                className="w-full p-2 border rounded-md" 
                            />
                        </div>
                        <div className="mt-2">
                            <label className="block font-medium">2- Are there specific products or services you are focusing on in these campaigns?</label>
                            <textarea 
                                name="primaryObjectives" 
                                value={formData.primaryObjectives} 
                                onChange={handleChange} 
                                className="w-full p-2 border rounded-md" 
                            />
                        </div>
                    </div>

                    <div className="p-4 bg-gray-50 shadow rounded-lg">
                        <h3 className="text-lg font-semibold mt-4">Target Audience</h3>
                        <div className="mt-2">
                            <label className="block font-medium">1- Who are you targeting with your ads? (e.g., demographics like age, gender, location; interests; behaviors)</label>
                            <textarea 
                                name="targetAudience" 
                                value={formData.targetAudience} 
                                onChange={handleChange} 
                                className="w-full p-2 border rounded-md" 
                            />
                        </div>
                        <div className="mt-2">
                            <label className="block font-medium">2- Do you have any customer personas or segments that you focus on?</label>
                            <textarea 
                                name="audienceDetails" 
                                value={formData.audienceDetails} 
                                onChange={handleChange} 
                                className="w-full p-2 border rounded-md" 
                            />
                        </div>
                    </div>

                    <div className="p-4 bg-gray-50 shadow rounded-lg">
                        <h3 className="text-lg font-semibold mt-4">Competitor Information</h3>
                        <div className="mt-2">
                            <label className="block font-medium">1- Who are your main competitors in the market?</label>
                            <textarea 
                                name="competitorInfo" 
                                value={formData.competitorInfo} 
                                onChange={handleChange} 
                                className="w-full p-2 border rounded-md" 
                            />
                        </div>
                        <div className="mt-2">
                            <label className="block font-medium">2- How do you differentiate yourself from them? (e.g., unique selling propositions, brand strengths)</label>
                            <textarea 
                                name="competitors" 
                                value={formData.competitors} 
                                onChange={handleChange} 
                                className="w-full p-2 border rounded-md" 
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-end space-x-4 mt-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-200 rounded-md mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        className="px-6 py-2 text-white bg-black hover:bg-gray-800 rounded-md"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CampaignProfileModal;
