import React, { useState, useContext } from 'react';
import { LogOut, Settings, Sparkles, User } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const UserDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext); 

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = async () => {
    try {
      await logout(); 
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleMyPlan = () => {
    // Navigate to the /my-plan route
    navigate('/my-plan');
  };

  return (
    <div className="relative">
      <button className="p-2 rounded-md hover:text-blue-600 relative" onClick={toggleDropdown}>
        <User className="h-5 w-5" />
      </button>
      {dropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg py-2">
          <div className="px-4 py-2 text-gray-600 text-sm font-semibold border-b border-gray-200">
            {user?.email || 'User Email'}
          </div>
          <button 
            onClick={handleMyPlan} 
            className="flex items-center p-2 w-full hover:bg-gray-100 text-left"
          >
            <Sparkles className="h-5 w-5 mr-2" />
            My Plan
          </button>
          <button 
            onClick={() => navigate('/settings')} 
            className="flex items-center p-2 w-full hover:bg-gray-100 text-left"
          >
            <Settings className="h-5 w-5 mr-2" />
            Settings
          </button>
          <button 
            onClick={handleLogout} 
            className="flex items-center p-2 w-full hover:bg-gray-100 text-left"
          >
            <LogOut className="h-5 w-5 mr-2" />
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
