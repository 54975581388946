import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, subDays } from 'date-fns';
import Header from './Header';
import AccountDataVisualization from './AccountDataVisualization';
import CreateModal from './CreateModal';
import NavigationBar from './NavigationBar';
import DatePickerComponent from './DatePickerComponent';
import PieChartVisualization from './PieChartVisualization';
import { useAccount } from '../../contexts/AccountContext';
import AutomatedReportsSection from './AutomatedReportsSection';
import TipsSection from './TipsSection';

const DashboardPage = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [comparisonData, setComparisonData] = useState([]);
  const [currencyCode, setCurrencyCode] = useState('');
  const { selectedAccount, setSelectedAccount } = useAccount();
  const [campaignSpendData, setCampaignSpendData] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [datePickerPosition, setDatePickerPosition] = useState({ top: 0, left: 0 });
  const [selectedRange, setSelectedRange] = useState({
    startDate: subDays(new Date(), 28),
    endDate: new Date(),
  });
  const [comparisonRange, setComparisonRange] = useState({
    startDate: null,
    endDate: null,
  });
  const dateButtonRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const authStatus = sessionStorage.getItem('authStatus');
    console.log('authStatus:', authStatus);
    if (authStatus !== null) {
      setIsConnected(JSON.parse(authStatus));
      setAuthChecked(true);
      console.log('isConnected:', isConnected);
    } else {
      const fetchAuthStatus = async () => {
        console.log('fetchAuthStatus fetchAuthStatus');

        try {
          const response = await fetch('https://api.adanthro.ai/api/auth/google_ads_status', {
            credentials: 'include'
          });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          setIsConnected(data.isConnected);
          sessionStorage.setItem('authStatus', JSON.stringify(data.isConnected));
          console.log('isConnected:', isConnected);
          console.log('data.isConnected:', data.isConnected);
          console.log('authStatus:', authStatus);

        } catch (error) {
          console.error('Failed to fetch authentication status:', error);
        } finally {
          setAuthChecked(true);
        }
      };
      fetchAuthStatus();
    }
  }, [navigate]);

  useEffect(() => {
    if (selectedAccount) {
      fetchAccountData(
        selectedAccount.id, // Adjusted to use id from context
        selectedRange,
        comparisonRange.startDate ? comparisonRange : null
      );
      fetchCampaignSpendData(selectedAccount.id, selectedRange); // Fetch campaign spend data
    }
  }, [selectedAccount, selectedRange, comparisonRange]);

  const handleAccountSelect = (accountId, managerCustomerId) => {
    setSelectedAccount(accountId);
    console.log('Selected account:', accountId, 'Manager ID:', managerCustomerId);
  };

  const fetchAccountData = async (accountId, dateRange, comparisonRange) => {
    try {
      const compareStartDate = comparisonRange ? format(comparisonRange.startDate, 'yyyy-MM-dd') : '';
      const compareEndDate = comparisonRange ? format(comparisonRange.endDate, 'yyyy-MM-dd') : '';

      const response = await fetch(
        `https://api.adanthro.ai/api/get-account-data/${accountId}?start_date=${format(dateRange.startDate, 'yyyy-MM-dd')}&end_date=${format(dateRange.endDate, 'yyyy-MM-dd')}&compare_start_date=${compareStartDate}&compare_end_date=${compareEndDate}`,
        { credentials: 'include' }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setAccountData(data.mainData || []);
      setComparisonData(data.comparisonData || []);
      setCurrencyCode(data.currencyCode || ''); // Set the currency code
    } catch (error) {
      console.error('Error fetching account data:', error);
    }
  };

  const fetchCampaignSpendData = async (accountId, dateRange) => {
    try {
      const response = await fetch(
        `https://api.adanthro.ai/api/get-campaign-spend/${accountId}?start_date=${format(dateRange.startDate, 'yyyy-MM-dd')}&end_date=${format(dateRange.endDate, 'yyyy-MM-dd')}`,
        { credentials: 'include' }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCampaignSpendData(data);
    } catch (error) {
      console.error('Error fetching campaign spend data:', error);
    }
  };

  const handleConnectGoogle = () => {
    sessionStorage.removeItem('authStatus');
    window.location.href = 'https://api.adanthro.ai/start-auth';
  };

  const handleDownload = () => {
    setShowCreateModal(false);
  };

  const handleOpenDatePicker = () => {
    const buttonRect = dateButtonRef.current.getBoundingClientRect();
    setDatePickerPosition({
      top: buttonRect.bottom + window.scrollY,
      left: buttonRect.left + window.scrollX
    });
    setShowDatePicker(true);
  };

  const handleApplyDateRange = (range) => {
    setSelectedRange({
      startDate: range.selection.startDate,
      endDate: range.selection.endDate
    });
    setComparisonRange({
      startDate: range.compare.startDate,
      endDate: range.compare.endDate
    });
    setShowDatePicker(false);
  };

  if (!authChecked) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-screen">
      <NavigationBar selectedAccount={selectedAccount} /> {/* Pass selectedAccount to NavigationBar */}
      <div className="flex flex-col flex-grow">
        <div className='p-4'>
          <Header onSelectAccount={(accountId, managerCustomerId, accountName) => setSelectedAccount({ id: accountId, name: accountName, managerCustomerId })} />
          <button
            ref={dateButtonRef}
            className="ml-4 border-b border-gray-400 hover:bg-gray-100 self-end"
            onClick={handleOpenDatePicker}
          >
            {format(selectedRange.startDate, 'MM/dd/yyyy')} - {format(selectedRange.endDate, 'MM/dd/yyyy')}
            {comparisonRange.startDate && comparisonRange.endDate && (
              <> (Comparing: {format(comparisonRange.startDate, 'MM/dd/yyyy')} - {format(comparisonRange.endDate, 'MM/dd/yyyy')})</>
            )}
          </button>
        </div>
{!isConnected && (
  <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-90">
    <div className="welcome-screen text-center p-6 bg-white rounded-lg shadow border border-gray-200 max-w-m">
      <h1 className="mb-4 text-2xl font-semibold">Welcome to AdAnthro</h1>
      <p className="mb-4">To get started with your campaign analysis, please connect your Google Ads account.</p>
      
      <button onClick={handleConnectGoogle} className="bg-black text-white p-3 rounded-lg">
        Connect Google Ads
      </button>

      <p className="mt-4 text-sm text-gray-600">
        We use your Google Ads data only to provide insights. <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">Learn more</a>.

      </p>
    </div>
  </div>
)}


        <div className="flex flex-col p-2 space-y-4 flex-grow overflow-y-auto">
          <div className="flex flex-row h-1/2 space-x-4">
            <div className="flex flex-col w-3/4 m-2">
              <div className="flex-grow">
                <AccountDataVisualization data={accountData} comparisonData={comparisonData} currencyCode={currencyCode} />
              </div>
            </div>
            <div className="flex flex-col w-1/4 m-2 overflow-hidden">
              <div className="flex-grow">
                <PieChartVisualization data={campaignSpendData} currencyCode={currencyCode} /> {/* Use PieChartVisualization component */}
              </div>
            </div>
          </div>

          <div className="flex flex-row space-x-4">
            <div className="flex flex-col w-1/2 m-2">
              <AutomatedReportsSection selectedAccount={selectedAccount} /> {/* Pass the selected account */}
            </div>
            <div className="flex flex-col w-1/2 m-2">
              <TipsSection /> {/* Display random tips */}
            </div>
          </div>
        </div>

        {showCreateModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
            <CreateModal onClose={() => setShowCreateModal(false)} onDownload={handleDownload} />
          </div>
        )}
        {showDatePicker && (
          <DatePickerComponent
            onApply={handleApplyDateRange}
            onClose={() => setShowDatePicker(false)}
            position={datePickerPosition}
            selectedRange={selectedRange}
            comparisonRange={comparisonRange}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
