import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ type = 'button', className = '', children, ...props }) => {
  return (
    <button
      type={type}
      className={`px-4 py-2 rounded-md bg-black hover:bg-gray-800 text-white ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Button;
