const DashboardPreview = () => {
  return (
      <img 
        src="/dashboard-cut.jpg" // Adjust the path as necessary
        alt="Dashboard Preview" 
        className="w-full h-full rounded-lg shadow-lg"
      />
  );
};

export default DashboardPreview;
