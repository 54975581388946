import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // for global styles
import MainApp from './App';  // Import MainApp as your main application component
import { AuthProvider } from './contexts/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <MainApp />
    </AuthProvider>
  </React.StrictMode>
);
