import React, { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Bars } from 'react-loader-spinner';
import { ArrowUp, ArrowDown } from 'lucide-react';

const TableVisualization = ({ data, comparisonData }) => {
  const { columns, rows } = data;

  console.log("Received columns:", columns);
  console.log("Received rows:", rows);
  console.log("Received comparison rows:", comparisonData);

  const parseValue = (value) => {
    if (typeof value === 'string' && !isNaN(value)) {
      const numericValue = value.replace(/[^0-9.-]+/g, '');
      return numericValue === '' ? 0 : parseFloat(numericValue);
    }
    return value;
  };

  const calculateDifference = (current, previous, isPercentage = false) => {
    const diff = current - previous;
    const icon = diff > 0 ? <ArrowUp size={10} /> : <ArrowDown size={10} />;
    const formattedDiff = isPercentage ? `${Math.abs(diff).toFixed(2)}%` : Math.abs(diff).toFixed(2);
    return { icon, formattedDiff, diff };
  };

  const mergeDataWithComparison = useMemo(() => {
    if (!comparisonData || comparisonData.length === 0) {
      return rows.map((row, index) => ({
        id: index,
        ...row.reduce((acc, cell, cellIndex) => {
          acc[cellIndex.toString()] = { value: cell };
          return acc;
        }, {})
      }));
    }

    return rows.map((row, rowIndex) => {
      const comparisonRow = comparisonData[rowIndex];
      return {
        id: rowIndex,
        ...row.reduce((acc, cell, cellIndex) => {
          const current = parseValue(cell);
          const previous = parseValue(comparisonRow[cellIndex]);
          const isPercentage = columns[cellIndex].toLowerCase().includes('ctr') || columns[cellIndex].toLowerCase().includes('rate');
          
          const comparison = !isNaN(current) && !isNaN(previous) ? calculateDifference(current, previous, isPercentage) : null;

          acc[cellIndex.toString()] = {
            value: cell,
            comparison: comparison
          };
          return acc;
        }, {})
      };
    });
  }, [rows, comparisonData, columns]);

  const formattedColumns = useMemo(() => {
    return columns.map((col, index) => ({
      field: index.toString(),
      headerName: col,
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>{params.value.value}</div>
          {params.value.comparison && (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px', fontSize: '0.75rem' }}>
              {params.value.comparison.icon} {params.value.comparison.formattedDiff}
            </div>
          )}
        </div>
      ),
      sortComparator: (v1, v2) => {
        const val1 = parseValue(v1.value);
        const val2 = parseValue(v2.value);
        return val1 - val2;
      },
    }));
  }, [columns]);

  console.log("Final merged data:", mergeDataWithComparison);

  if (!rows || rows.length === 0) {
    return (
      <div className="flex justify-center items-center h-full">
        <Bars color="#195afe" height={80} width={80} />
      </div>
    );
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow h-full w-full border border-gray-200 max-w-screen-lg mx-auto">
      <h2 className="font-semibold mb-2">Analysis Data</h2>
      <div className="overflow-auto h-full">
        <DataGrid
          rows={mergeDataWithComparison}
          columns={formattedColumns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          sx={{
            border: 'none',
            height: '100%',
            '& .MuiDataGrid-cell': {
              padding: '8px',
            },
          }}
        />
      </div>
    </div>
  );
};

export default TableVisualization;
