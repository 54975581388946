import React, { useState, useEffect } from 'react';
import { DateRange, DefinedRange } from 'react-date-range';
import { addDays } from 'date-fns';
import Switch from '@mui/material/Switch';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './customDateRangeStyles.css';

const DatePickerComponent = ({ onApply, onClose, position, selectedRange, comparisonRange }) => {
  const [state, setState] = useState({
    selection: {
      startDate: selectedRange.startDate,
      endDate: selectedRange.endDate,
      key: 'selection'
    },
    compare: {
      startDate: comparisonRange.startDate || new Date(),
      endDate: comparisonRange.endDate || addDays(new Date(), 3),
      key: 'compare'
    }
  });
  const [showCompare, setShowCompare] = useState(!!comparisonRange.startDate);

  useEffect(() => {
    setState({
      selection: {
        startDate: selectedRange.startDate,
        endDate: selectedRange.endDate,
        key: 'selection'
      },
      compare: {
        startDate: comparisonRange.startDate || new Date(),
        endDate: comparisonRange.endDate || addDays(new Date(), 3),
        key: 'compare'
      }
    });
  }, [selectedRange, comparisonRange]);

  const handleCompareToggle = () => {
    setShowCompare(!showCompare);
  };

  return (
    <div className="fixed z-50 shadow shadow-2xl" style={{ top: position.top, left: position.left }}>
      <div className="bg-white p-4 rounded-lg shadow-lg shadow-gray-400 border border-gray-200">
        <h2 className="text-lg font-semibold mb-4">Select Date Range</h2>
        <div className="flex">
          <div className="w-1/4 mr-10">
            <DefinedRange
              onChange={item => setState({ ...state, ...item })}
              ranges={[state.selection]}
            />
            <div className="mt-4 flex items-center">
              <Switch
                checked={showCompare}
                onChange={handleCompareToggle}
                color="primary"
              />
              <span className="ml-2">Compare</span>
            </div>
          </div>
          <div className="border-l border-gray-200">
            <DateRange
              onChange={item => setState({ ...state, ...item })}
              months={1}
              minDate={addDays(new Date(), -1095)}
              maxDate={addDays(new Date(), 30)}
              direction="vertical"
              scroll={{ enabled: true }}
              ranges={showCompare ? [state.selection, state.compare] : [state.selection]}
              rangeColors={['#195afe', '#F7996E']} 
            />
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            className="px-4 py-2 bg-gray-200 rounded-md mr-2"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="px-4 py-2 text-white bg-black hover:bg-gray-800 rounded-md"
            onClick={() => onApply(showCompare ? state : { selection: state.selection, compare: { startDate: null, endDate: null } })}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default DatePickerComponent;