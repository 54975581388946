import React, { useState, useEffect, useContext } from 'react';
import Header from './Header';
import NavigationBar from './NavigationBar';
import Button from './ui/Button'; 
import { AuthContext } from '../../contexts/AuthContext';

const SettingsPage = () => {
  const { user, setUser } = useContext(AuthContext);  // Use AuthContext to get current user data

  const [newEmail, setNewEmail] = useState(user?.email || '');
  const [newName, setNewName] = useState(user?.name || '');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isChangingName, setIsChangingName] = useState(false);
  const [message, setMessage] = useState('');
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);

  useEffect(() => {
    fetchConnectedAccounts();
  }, []);

  const fetchConnectedAccounts = async () => {
    setIsLoadingAccounts(true);
    try {
      const response = await fetch('https://api.adanthro.ai/api/get-connected-accounts', {
        method: 'GET',
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        setConnectedAccounts(data.accounts);
      } else {
        setMessage(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error fetching connected accounts:', error);
      setMessage('An unexpected error occurred.');
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  const handleConnectGoogle = () => {
    try {
      window.location.href = 'https://api.adanthro.ai/start-auth';
    } catch (error) {
      console.error('Error connecting Google account:', error);
      setMessage('An unexpected error occurred.');
    }
  };
  
  const handleDisconnectGoogle = async () => {
    try {
      const response = await fetch('https://api.adanthro.ai/api/disconnect-account', {
        method: 'DELETE',
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Google account disconnected successfully.');
        fetchConnectedAccounts(); // Refresh the list of connected accounts
      } else {
        setMessage(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error disconnecting Google account:', error);
      setMessage('An unexpected error occurred.');
    }
  };

  const handleEmailChange = async () => {
    setIsChangingEmail(true);
    try {
      const response = await fetch('https://api.adanthro.ai/api/request-email-change', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ newEmail }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Email change requested. Please check both your old and new email addresses for verification links.');
        setUser(prevUser => ({ ...prevUser, email: newEmail }));  // Update user in AuthContext
      } else {
        setMessage(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error requesting email change:', error);
      setMessage('An unexpected error occurred.');
    } finally {
      setIsChangingEmail(false);
    }
  };

  const handleNameChange = async () => {
    setIsChangingName(true);
    try {
      const response = await fetch('https://api.adanthro.ai/api/change-name', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ newName }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Name changed successfully.');
        setUser(prevUser => ({ ...prevUser, name: newName }));  // Update user in AuthContext
      } else {
        setMessage(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error changing name:', error);
      setMessage('An unexpected error occurred.');
    } finally {
      setIsChangingName(false);
    }
  };

  const handlePasswordChange = async () => {
    setIsChangingPassword(true);
    try {
      const response = await fetch('https://api.adanthro.ai/api/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ currentPassword, newPassword }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Password changed successfully.');
      } else {
        setMessage(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error changing password:', error);
      setMessage('An unexpected error occurred.');
    } finally {
      setIsChangingPassword(false);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await fetch('https://api.adanthro.ai/api/delete-account', {
        method: 'DELETE',
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Account deleted successfully.');
        // Optionally redirect the user after account deletion
      } else {
        setMessage(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      setMessage('An unexpected error occurred.');
    }
  };

  return (
    <div className="flex h-screen">
      <NavigationBar /> {/* Include NavigationBar */}
      <div className="flex-grow flex flex-col h-full p-2">
        <Header /> {/* Include Header */}
        <div className="w-1/2 mx-auto p-8 bg-white rounded-lg shadow-md">
          <h1 className="text-3xl font-semibold mb-6">Settings</h1>

          <div className="mb-8">
            <div className="flex items-center mb-4">
              <h2 className="text-xl font-semibold mr-4">Name</h2>
              <p className="mr-4">{user?.name}</p>
              <input
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                placeholder="New Name"
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <Button onClick={handleNameChange} disabled={isChangingName} className="ml-4">
                {isChangingName ? 'Changing...' : 'Change'}
              </Button>
            </div>
          </div>

          <div className="mb-8">
            <div className="flex items-center mb-4">
              <h2 className="text-xl font-semibold mr-4">Email</h2>
              <p className="mr-4">{user?.email}</p>
              <input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="New Email"
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <Button onClick={handleEmailChange} disabled={isChangingEmail} className="ml-4">
                {isChangingEmail ? 'Requesting...' : 'Change'}
              </Button>
            </div>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-2">Password</h2>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Current Password"
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />
            <Button onClick={handlePasswordChange} disabled={isChangingPassword}>
              {isChangingPassword ? 'Changing...' : 'Change Password'}
            </Button>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-2">Connected Ads Account</h2>
            {isLoadingAccounts ? (
              <p>Loading connected accounts...</p>
            ) : (
              connectedAccounts.map((account) => (
                <div key={account.id} className="mb-4">
                  <div className="flex justify-between items-center">
                    <p>{account.provider}: {account.email}</p>
                    <Button onClick={handleDisconnectGoogle} className="bg-red-600 hover:bg-red-800 text-white">
                      Disconnect
                    </Button>
                  </div>
                </div>
              ))
            )}
            <Button onClick={handleConnectGoogle}>
              Connect Google Account
            </Button>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-2">Delete Account</h2>
            <p className="text-red-600">This action cannot be undone.</p>
            <Button onClick={handleDeleteAccount} className="bg-red-600 text-white">
              Delete Account
            </Button>
          </div>

          {message && <p className="mt-4 text-gray-800">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
