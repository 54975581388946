// contexts/AccountContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AccountContext = createContext();

export const useAccount = () => useContext(AccountContext);

export const AccountProvider = ({ children }) => {
  const [selectedAccount, setSelectedAccount] = useState(() => {
    const savedAccount = localStorage.getItem('selectedAccount');
    console.log('Loading account from local storage:', savedAccount);
    return savedAccount ? JSON.parse(savedAccount) : { id: null, name: '' };
  });

  useEffect(() => {
    console.log('Selected account changed:', selectedAccount);
    if (selectedAccount.id) {
        localStorage.setItem('selectedAccount', JSON.stringify({
            id: selectedAccount.id,
            name: selectedAccount.name,
            managerCustomerId: selectedAccount.managerCustomerId
        }));
        console.log('Saved account to local storage:', selectedAccount);
    }
}, [selectedAccount]);

  return (
    <AccountContext.Provider value={{ selectedAccount, setSelectedAccount }}>
      {children}
    </AccountContext.Provider>
  );
};
