import React from 'react';
import { Badge } from "./ui/badge";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Check, HelpCircle } from "lucide-react";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { useNavigate } from 'react-router-dom';

const PopularPlanType = {
  NO: 0,
  YES: 1,
};

const pricingList = [
  {
    planId: 2,
    title: "Relax",
    popular: PopularPlanType.NO,
    price: 9.90,
    description: "Suitable for small business owners and solopreneurs needing occasional, cost-effective campaign analysis.",
    buttonText: "Rest and Recharge",
    benefitList: [
      { benefit: "3 Analysis / mo", tooltip: "3 new analysis windows per month, each with up to 1000 messages per chat." },
      { benefit: "5,000 Messages per Chat", tooltip: "Message limit per analysis, ensuring ample room for detailed discussions." },
      { benefit: "5 Active Automated Reporting", tooltip: "Schedule and receive automatic reports via email." },
      { benefit: "Company and Market Details", tooltip: "Add and manage details about your company and competitors for personalized analysis." }
    ]
  },
  {
    planId: 4,
    title: "Supercharge",
    popular: PopularPlanType.YES,
    price: 39.90,
    description: "SUPERCHARGE!",
    buttonText: "Try Epic Mode",
    benefitList: [
      { benefit: "20 Analysis / mo", tooltip: "20 new analysis window per month, each with unlimited chat." },
      { benefit: "Unlimited Messages per Chat", tooltip: "Enjoy unlimited messaging, allowing for thorough and continuous discussion." },
      { benefit: "20 Active Automated Reporting", tooltip: "High-capacity scheduling and delivery of automated reports." },
      { benefit: "Company and Market Details", tooltip: "Comprehensive management of your company and competitive landscape details." },
      { benefit: "Priority Customer Support", tooltip: "Top-tier, dedicated support ensuring minimal downtime." },
    ]
  },
  {
    planId: 3,
    title: "Pro",
    popular: PopularPlanType.NO,
    price: 29.90,
    description: "Suitable for agencies, freelancers, and growing businesses requiring frequent, in-depth campaign analysis.",
    buttonText: "Forge Ahead—Try Pro",
    benefitList: [
      { benefit: "10 Analysis / mo", tooltip: "10 new analysis windows per month, each with unlimited chat." },
      { benefit: "Unlimited Messages per Chat", tooltip: "Enjoy unlimited messaging, allowing for thorough and continuous discussion." },
      { benefit: "10 Active Automated Reporting", tooltip: "Schedule and receive automatic reports via email." },
      { benefit: "Company and Market Details", tooltip: "Add and manage details about your company and competitors for personalized analysis." },
    ]
  }
];
export const Pricing = ({ onPlanSelect }) => {
  const navigate = useNavigate();

  const handlePlanSelection = (plan) => {
    if (onPlanSelect) {
      onPlanSelect(plan);
    } else {
      navigate('/register', { state: { selectedPlanId: plan.planId, selectedPlanTitle: plan.title } });
    }
  };

  return (
    <section id="pricing" className="container py-24 sm:py-32">
      {!onPlanSelect && (
        <h2 className="text-xl md:text-2xl text-center">
          Enjoy a 7-Day Free Trial with Any Plan!
        </h2>
      )}
  <h2 className="text-3xl md:text-4xl font-bold text-center mt-6">
    Choose Your Plan
    <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">
      {" "}
      and Supercharge Your Campaigns
    </span>
  </h2>
  <h3 className="text-xl text-center text-muted-foreground pt-4 pb-8">
    Find the right plan that fits your marketing needs and start optimizing your campaigns today.
  </h3>
      <div className="grid md:grid-cols-3 gap-8">
        {pricingList.map((pricing) => (
          <Card
            key={pricing.planId}
            className={pricing.popular === PopularPlanType.YES ? "drop-shadow-xl shadow-black/10 dark:shadow-white/10" : ""}
          >
            <CardHeader>
              <CardTitle className="flex items-center justify-between">
                {pricing.title}
                {pricing.popular === PopularPlanType.YES && (
                  <Badge variant="secondary" className="text-sm text-primary">
                    Most popular
                  </Badge>
                )}
              </CardTitle>
              <div>
                <span className="text-3xl font-bold">${pricing.price}</span>
                <span className="text-muted-foreground"> /month</span>
              </div>
              <CardDescription className={pricing.title === "Supercharge" ? "font-bold" : ""}>
                {pricing.description}
              </CardDescription>
            </CardHeader>

            <CardContent>
              <Button className="w-full" onClick={() => handlePlanSelection(pricing)}>
                {pricing.buttonText}
              </Button>
            </CardContent>

            <hr className="w-4/5 m-auto mb-4" />

            <CardFooter className="flex">
              <div className="space-y-4">
                {pricing.benefitList.map(({ benefit, tooltip }) => (
                  <span key={benefit} className="flex items-center">
                    <Check className="text-green-500" />{" "}
                    <h3 className="ml-2">{benefit}</h3>
                    {tooltip && (
                      <>
                        <HelpCircle
                          className="ml-2 text-muted-foreground cursor-pointer"
                          data-tooltip-id={`tooltip-${benefit}`}
                          data-tooltip-content={tooltip}
                        />
                        <Tooltip id={`tooltip-${benefit}`} place="top" />
                      </>
                    )}
                  </span>
                ))}
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>
    </section>
  );
};
