import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusCircle, Trash2 } from 'lucide-react';
import CreateModal from './CreateModal';
import ConfirmationModal from './ConfirmationModal';

const AnalysisHistory = ({ selectedAccount }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [analysisToDelete, setAnalysisToDelete] = useState(null);
  const [analysisHistory, setAnalysisHistory] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnalysisHistory = async () => {
      if (!selectedAccount) return;

      try {
        const response = await fetch(`https://api.adanthro.ai/api/get-analysis-history?accountId=${selectedAccount.id}`, {
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          setAnalysisHistory(data);
        } else {
          console.error('Failed to fetch analysis history');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchAnalysisHistory();
  }, [selectedAccount]);

  const handleNewAnalysis = () => {
    setShowCreateModal(true);
  };

  const handleAnalysisClick = (id) => {
    navigate(`/analysis/${id}`);
  };

  const handleDeleteClick = (analysis) => {
    setAnalysisToDelete(analysis);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(`https://api.adanthro.ai/api/delete-analysis/${analysisToDelete.id}`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (response.ok) {
        setAnalysisHistory((prev) => prev.filter((a) => a.id !== analysisToDelete.id));
        setShowConfirmationModal(false);
      } else {
        console.error('Failed to delete analysis');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <h2 className="text-xl font-bold mb-4">Analysis History</h2>
      <button
        onClick={handleNewAnalysis}
        className="flex items-center space-x-2 text-white bg-black hover:bg-gray-800 p-2 rounded-md mb-4"
      >
        <PlusCircle className="h-5 w-5" />
        <span>New Analysis</span>
      </button>
      <div className="flex-grow overflow-y-auto">
        <ul className="space-y-2">
          {analysisHistory.map((analysis) => (
            <li
              key={analysis.id}
              className="flex items-center justify-between text-gray-700 hover:bg-gray-100 p-2 rounded-md cursor-pointer border border-gray-200 relative group"
              onClick={() => handleAnalysisClick(analysis.id)}
            >
              <div className="flex-grow">
                <p className="font-bold">{analysis.name}</p>
                <p className="text-sm text-gray-500">{analysis.campaign_name}</p>
                <p className="text-sm text-gray-500">{analysis.template}</p>
              </div>
              <button
                className="text-red-600 hover:text-red-800 ml-4 hidden group-hover:block"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(analysis);
                }}
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </li>
          ))}
        </ul>
      </div>

      {showCreateModal && <CreateModal onClose={() => setShowCreateModal(false)} onDownload={() => setShowCreateModal(false)} />}
      {showConfirmationModal && (
        <ConfirmationModal
          title="Delete Analysis"
          message="Are you sure you want to delete this analysis? This action cannot be undone and deleting the analysis will not refill your analysis slots for the month."
          confirmButtonText="Yes, Delete"
          onClose={() => setShowConfirmationModal(false)}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default AnalysisHistory;
