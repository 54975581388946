import { BarChart2, Search, Edit, Mail, Users, Target, PieChart } from 'lucide-react';

const useCases = [
  {
    icon: <BarChart2 className="w-12 h-12 text-black" />,
    title: 'Performance Analysis',
    example: "Can you give me a detailed analysis of the campaign performance for our 'Spring Sale' ad?",
    description: 'Get detailed insights into the overall effectiveness of your Google Ads campaigns, focusing on key performance metrics.',
  },
  {
    icon: <PieChart className="w-12 h-12 text-black" />,
    title: 'Automated Reporting',
    example: "Automate your reporting process to save time.",
    description: 'Create reports with custom prompts that can be scheduled and sent directly to your inbox or your stakeholders.',
  },
  {
    icon: <Search className="w-12 h-12 text-black" />,
    title: 'Optimization',
    example: "Which keywords should we keep and which should we stop using for our 'Winter Discount' campaign?",
    description: 'Receive AI-driven advice on various optimization aspects, including keywords, bidding strategies, and ad placements to enhance campaign efficiency.',
  },
  {
    icon: <Edit className="w-12 h-12 text-black" />,
    title: 'Content Strategy',
    example: "Analyze our ad copy for the 'Summer Collection' campaign and provide five new versions that could perform better.",
    description: 'Data-informed recommendations to improve ad copy, creatives, and overall messaging for higher engagement and performance.',
  },
  {
    icon: <Mail className="w-12 h-12 text-black" />,
    title: 'Reporting and Communication',
    example: "Can you draft an email summarizing the performance of our 'Holiday Deals' campaign for our stakeholders?",
    description: 'Generate detailed content summarizing campaign results for easy sharing with stakeholders, tailored to specific campaign outcomes.',
  },
  {
    icon: <Target className="w-12 h-12 text-black" />,
    title: 'Google Ads Guidance',
    example: "How can I exclude that keyword from the 'Black Friday' campaign?",
    description: 'Get step-by-step guidance on managing your Google Ads account, including tasks like keyword exclusion, ad scheduling, and audience targeting adjustments.',
  },

];

const UseCasesSection = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Discover How Our AI Can Elevate Your Campaigns</h2>
        <p className="text-center mb-12">
        Our platform empowers you to enhance your Google Ads campaigns through smart, conversational AI. Explore how you can leverage our tools for superior performance:
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {useCases.map((useCase, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center">
              <div className="mb-4">{useCase.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{useCase.title}</h3>
              <p className="italic mb-2">Example: "{useCase.example}"</p>
              <p>{useCase.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UseCasesSection;
