import React from 'react';
import { useLocation } from 'react-router-dom';
import { Bell } from 'lucide-react';
import UserDropdown from './UserDropdown';
import AccountSelector from './AccountSelector';
import { useAccount } from '../../contexts/AccountContext'; // Import the useAccount hook

const Header = () => {
  const location = useLocation();
  const { setSelectedAccount } = useAccount(); // Use the setSelectedAccount function

  // Mapping routes to page titles
  const pageTitles = {
    '/': 'Dashboard',
    '/settings': 'Settings',
    '/automated-reports': 'Automated Reports',
  };

  // Check for dynamic routes like `/analysis/:id`
  const getPageTitle = () => {
    if (location.pathname.startsWith('/analysis')) {
      return 'Analysis Page';
    }
    return pageTitles[location.pathname] || 'Page Title';
  };

  return (
    <header className="flex justify-between items-center p-2 pl-4 bg-white rounded-lg border border-gray-200 shadow mb-4">
      <div className="flex items-center">
        <h1 className="text-2xl font-semibold mr-4">
          {getPageTitle()}
        </h1>
        <AccountSelector onSelectAccount={setSelectedAccount} />
      </div>
      <div className="flex space-x-4 relative">
        <button className="p-2 rounded-md hover:text-blue-600">
          <Bell className="h-5 w-5" />
        </button>
        <UserDropdown />
      </div>
    </header>
  );
};

export default Header;
