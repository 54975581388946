import React, { useState, useEffect } from 'react';
import { ChevronRight, ChevronDown, Search } from 'lucide-react';
import analysisTemplates from './config/analysisTemplates.json';
import { useNavigate } from 'react-router-dom';
import ReviewModal from './ReviewModal';

const advertisingChannelTypeMapping = {
  0: 'UNSPECIFIED',
  1: 'UNKNOWN',
  2: 'SEARCH',
  3: 'DISPLAY',
  4: 'SHOPPING',
  5: 'HOTEL',
  6: 'VIDEO',
  7: 'MULTI_CHANNEL',
  8: 'LOCAL',
  9: 'SMART',
  10: 'PERFORMANCE_MAX',
  11: 'LOCAL_SERVICES',
  12: 'DISCOVERY',
  13: 'TRAVEL'
};

const AccountRadioTree = ({ accounts, onSelectAccount }) => {
  return (
    <ul className="tree-view pl-4 overflow-y-auto h-64">
      {accounts.map(account => (
        <TreeNode key={account.id} account={account} onSelectAccount={onSelectAccount} />
      ))}
    </ul>
  );
};

const TreeNode = ({ account, onSelectAccount }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandCollapse = () => {
    setExpanded(!expanded);
  };

  const isLeafNode = !account.clients || account.clients.length === 0;
  const displayName = account.name || account.id;  // Use name if available, otherwise fall back to ID

  return (
    <li className="flex flex-col">
      <div className="flex items-center space-x-2">
        {account.clients && account.clients.length > 0 ? (
          <>
            <span className="toggle cursor-pointer" onClick={handleExpandCollapse}>
              {expanded ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
            </span>
            <label
              htmlFor={`acc_${account.id}`}
              className="cursor-pointer"
              onClick={handleExpandCollapse}
            >
              {displayName}
            </label>
          </>
        ) : (
          <>
            <input
              type="radio"
              id={`acc_${account.id}`}
              name="accounts"
              value={account.id}
              data-manager-id={account.managerId || ''}
              onChange={e => onSelectAccount(e.target.value, e.target.dataset.managerId)}
            />
            <label
              htmlFor={`acc_${account.id}`}
              className="cursor-pointer"
            >
              {displayName}
            </label>
          </>
        )}
      </div>
      {expanded && account.clients && account.clients.length > 0 && (
        <ul className="pl-4">
          {account.clients.map(client => (
            <TreeNode key={client.id} account={{ ...client, managerId: account.id }} onSelectAccount={onSelectAccount} />
          ))}
        </ul>
      )}
    </li>
  );
};

const CampaignRadioButtons = ({ campaigns, onSelectCampaign, searchTerm }) => {
  const filteredCampaigns = campaigns.filter(campaign =>
    campaign.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <ul className="campaigns-list overflow-y-auto h-64">
      {filteredCampaigns.map(campaign => (
        <li key={campaign.id} className="flex items-center space-x-2">
          <input
            type="radio"
            id={`campaign_${campaign.id}`}
            name="campaigns"
            value={campaign.id}
            onChange={() => onSelectCampaign(campaign.id, campaign.name)}
          />
          <label htmlFor={`campaign_${campaign.id}`}>{campaign.name}</label>
        </li>
      ))}
    </ul>
  );
};

const AnalysisTemplates = ({ templates, onSelectTemplate, selectedTemplate }) => (
  <div className="analysis-templates space-y-4">
    {Object.keys(templates).map(group => (
      <div key={group} className="template-group">
        <h3 className="font-semibold mb-2">{group}</h3>
        <div className="template-buttons flex flex-wrap">
          {Object.keys(templates[group]).map(templateKey => (
            <button
              key={templateKey}
              className={`template-button p-2 m-1 rounded-md ${selectedTemplate === templateKey ? 'border border-gray-200 shadow-inner shadow-gray-200 text-black ' : 'border border-gray-200 shadow-sm text-gray-600 hover:shadow-md'}`}
              onClick={() => onSelectTemplate(group, templateKey)}
              style={{ whiteSpace: 'nowrap' }}
            >
              {templateKey}
            </button>
          ))}
        </div>
      </div>
    ))}
  </div>
);

const CreateModal = ({ onClose }) => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [managerCustomerId, setManagerCustomerId] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignName, setCampaignName] = useState(''); // New state for campaign name
  const [campaignType, setCampaignType] = useState(null);
  const [description, setDescription] = useState("Custom template description here");
  const [selectedTemplateGroup, setSelectedTemplateGroup] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templates, setTemplates] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [analysisName, setAnalysisName] = useState(''); // New state for analysis name
  const [step, setStep] = useState(1); // New state for step navigation
  const navigate = useNavigate(); // Use navigate

  useEffect(() => {
    fetchAccounts().then(data => {
      setAccounts(data);
    });
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      fetchCampaigns(selectedAccount, managerCustomerId).then(data => {
        setCampaigns(data);
      });
    }
  }, [selectedAccount, managerCustomerId]);

  useEffect(() => {
    if (selectedCampaign) {
      const campaign = campaigns.find(campaign => campaign.id === selectedCampaign);
      const type = campaign?.type;
      const mappedType = advertisingChannelTypeMapping[type];
      setCampaignType(mappedType);
      if (analysisTemplates[mappedType]) {
        setTemplates(analysisTemplates[mappedType]);
      } else {
        setTemplates({});
      }
    }
  }, [selectedCampaign, campaigns]);

  useEffect(() => {
    if (selectedTemplateGroup && selectedTemplate && templates[selectedTemplateGroup]) {
      const templateDetails = templates[selectedTemplateGroup][selectedTemplate];
      setDescription(templateDetails?.description || "Custom template description here");
    }
  }, [selectedTemplateGroup, selectedTemplate, templates]);

  const handleSelectTemplate = (group, templateKey) => {
    setSelectedTemplateGroup(group);
    setSelectedTemplate(templateKey);
  };

  const handleSelectAccount = (accountId, managerCustomerId) => {
    setSelectedAccount(accountId);
    setManagerCustomerId(managerCustomerId);
  };

  const handleSelectCampaign = (campaignId, campaignName) => {
    setSelectedCampaign(campaignId);
    setCampaignName(campaignName); // Set campaign name
  };

  const fetchAccounts = async () => {
    const response = await fetch('https://api.adanthro.ai/api/get-account-details', { credentials: 'include' });
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json();
  };

  const fetchCampaigns = async (accountId, managerCustomerId) => {
    const response = await fetch(`https://api.adanthro.ai/api/get-campaigns/${accountId}?managerCustomerId=${managerCustomerId}`, { credentials: 'include' });
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json();
  };

  const createAnalysis = async () => {
    const queryDetails = templates[selectedTemplateGroup][selectedTemplate];
    queryDetails.client_id = selectedAccount;
    queryDetails.manager_customer_id = managerCustomerId;
    const response = await fetch('https://api.adanthro.ai/api/create-analysis', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        campaign_id: selectedCampaign,
        campaign_name: campaignName, // Include campaign name
        template: selectedTemplate,
        query_details: queryDetails,
        client_id: selectedAccount,
        manager_customer_id: managerCustomerId,
        name: analysisName // Include analysis name
      })
    });
    const result = await response.json();
    if (!response.ok) throw new Error(result.error || 'Failed to create analysis');
    onClose();
    navigate(`/analysis/${result.analysis_id}`); // Navigate to the new analysis page
  };

  return (
    <div className="modal fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
      <div className="modal-content bg-white p-6 rounded-md shadow-md w-full max-w-7xl flex flex-col space-y-4 relative">
        <button className="absolute top-2 right-2 text-gray-400 hover:text-gray-600" onClick={onClose}>
          &times;
        </button>
        {step === 1 && (
          <>
            <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
              <div className="modal-section w-1/5 border-r pr-4 flex flex-col">
                <h2 className="text-lg font-semibold mb-2">1. Select an Account</h2>
                <AccountRadioTree accounts={accounts} onSelectAccount={handleSelectAccount} />
              </div>
              <div className="modal-section w-1/5 border-r pr-4 flex flex-col">
                <h2 className="text-lg font-semibold mb-2">2. Select Campaign</h2>
                <div className="flex items-center mb-2">
                  <Search className="h-5 w-5 text-gray-500" />
                  <input
                    type="text"
                    placeholder="Search campaigns..."
                    className="ml-2 p-1 border rounded-md"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <CampaignRadioButtons campaigns={campaigns} onSelectCampaign={handleSelectCampaign} searchTerm={searchTerm} />
              </div>

              {selectedCampaign && (
                <div className="modal-section flex-1">
                  <h2 className="text-lg font-semibold mb-2">3. Choose an analysis type</h2>
                  <AnalysisTemplates templates={templates} onSelectTemplate={handleSelectTemplate} selectedTemplate={selectedTemplate} />
                  <p className="text-sm mt-2">{description}</p>
                </div>
              )}
            </div>
            {selectedTemplate && (
              <button
                onClick={() => setStep(2)} // Move to next step
                className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 self-end"
              >
                Next
              </button>
            )}
          </>
        )}
        {step === 2 && (
          <>
            <div className="modal-section flex-1">
              <h2 className="text-lg font-semibold mb-2">Review and Name Your Analysis</h2>
              <div className="space-y-2">
                <p><strong>Account:</strong> {selectedAccount}</p>
                <p><strong>Campaign:</strong> {campaignName}</p>
                <p><strong>Analysis Type:</strong> {selectedTemplate}</p>
                <label className="block text-sm font-medium text-gray-700" htmlFor="analysis-name">
                  Analysis Name
                </label>
                <input
                  id="analysis-name"
                  type="text"
                  className="mt-1 p-2 border rounded-md w-full"
                  value={analysisName}
                  onChange={(e) => setAnalysisName(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => setStep(1)} // Move back to previous step
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
              >
                Back
              </button>
              <button
                onClick={createAnalysis}
                className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800"
              >
                Create Analysis
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateModal;