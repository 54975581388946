import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";

const FAQList = [
  {
    question: "What is AdAnthro and how does it work?",
    answer: "AdAnthro is an AI-powered platform designed to provide insights and recommendations for optimizing Google Ads campaigns. You can chat with the AI, request automated reports, and receive personalized insights to enhance your ad performance.",
    value: "item-1",
  },
  {
    question: "Does AdAnthro manage my campaigns for me?",
    answer: "No, AdAnthro does not manage campaigns directly. Instead, it provides AI-driven insights and recommendations to help you manage and optimize your campaigns. You interact with the AI to get the most effective guidance for your marketing strategies.",
    value: "item-2",
  },
  {
    question: "Is my data secure with AdAnthro?",
    answer: "Yes, we prioritize privacy and data security. Your Google Ads data, chat interactions, and business information are never shared with third parties outside of what is required to maintain the platform's functionality. OpenAI, which powers the AI, does not use your data to train its models, and data is deleted after 30 days unless required by law.",
    value: "item-3",
  },
  {
    question: "What kinds of reports can I generate with AdAnthro?",
    answer: "AdAnthro allows you to create automated, scheduled analyses of your Google Ads campaigns. You can set up reports based on campaign performance, competitor analysis, or historical data, which are delivered directly to you as per the schedule you configure.",
    value: "item-4",
  },
  {
    question: "Can AdAnthro help me with competitor analysis?",
    answer: "Yes, AdAnthro can assist with competitor analysis by providing insights based on your input and helping you compare performance metrics against industry standards or specific competitors, allowing you to refine your strategies.",
    value: "item-5",
  },
  {
    question: "What are the subscription options and limits?",
    answer: "AdAnthro offers different subscription plans. Each plan comes with a limit on enabled reports and monthly analysis caps, depending on the tier. For example, the Relax plan includes a message limit and report restriction based on your subscription level.",
    value: "item-6",
  },
  {
    question: "How can I manage my subscription?",
    answer: "You can manage your subscription through your account settings. This includes upgrading or downgrading plans, as well as canceling your subscription. Any changes will take effect in the next billing cycle.",
    value: "item-7",
  },
  {
    question: "Is there a trial period available?",
    answer: "Yes, AdAnthro offers a trial period that allows you to explore the platform's features and experience how it can enhance your Google Ads campaigns before committing to a subscription.",
    value: "item-8",
  },
];

export const FAQ = () => {
  return (
    <section
      id="faq"
      className="container py-24 sm:py-32"
    >
      <h2 className="text-3xl md:text-4xl font-bold mb-4">
        Frequently Asked{" "}
        <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">
          Questions
        </span>
      </h2>

      <Accordion
        type="single"
        collapsible
        className="w-full AccordionRoot"
      >
        {FAQList.map(({ question, answer, value }) => (
          <AccordionItem
            key={value}
            value={value}
          >
            <AccordionTrigger className="text-left">
              {question}
            </AccordionTrigger>

            <AccordionContent>{answer}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>

      <h3 className="font-medium mt-4">
        Still have questions?{" "}
        <a
          rel="noreferrer noopener"
          href="#"
          className="text-primary transition-all border-primary hover:border-b-2"
        >
          Contact us
        </a>
      </h3>
    </section>
  );
};
