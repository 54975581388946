import { Badge } from "./ui/badge";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import image from "../assets/real-time.jpg";
import image3 from "../assets/end-to-end.jpg";
import image4 from "../assets/auto-report.jpg";

const features = [
  {
    title: "Real-Time Expert",
    description:
      "Engage in conversations with an expert-level AI assistant that provides immediate insights and guidance, helping you make informed decisions throughout your campaign.",
    image: image,
  },
  {
    title: "End-to-End Campaign Guidance",
    description:
      "Receive expert advice at every stage of your campaign, from initial strategy to final analysis, ensuring you have the insights you need to succeed.",
    image: image3,
  },
  {
    title: "Automated Reporting",
    description:
      "Get customized insights delivered directly to your inbox through automated reports, helping you stay informed and make data-driven decisions effortlessly.",
    image: image4,
  },
];

const featureList = [
  "In-depth Campaign Analysis",
  "Advanced Keyword Optimization",
  "Creative Content Strategy",
  "Competitor Insights",
  "Google Ads Management Guidance",
  "Custom Reporting",
  "Scalable Solutions for All Business Sizes",
];

export const Features = () => {
  return (
    <section
      id="features"
      className="container py-24 sm:py-32 space-y-8"
    >
      <h2 className="text-3xl lg:text-4xl font-bold md:text-center">
        Discover Our{" "}
        <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">
          Great Features
        </span>
      </h2>

      <div className="flex flex-wrap md:justify-center gap-4">
        {featureList.map((feature) => (
          <div key={feature}>
            <Badge
              variant="secondary"
              className="text-sm"
            >
              {feature}
            </Badge>
          </div>
        ))}
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map(({ title, description, image }) => (
          <Card key={title}>
            <CardHeader>
              <CardTitle>{title}</CardTitle>
            </CardHeader>

            <CardContent>{description}</CardContent>

            <CardFooter>
              <img
                src={image}
                alt={title}
                className="w-[200px] lg:w-[300px] mx-auto"
              />
            </CardFooter>
          </Card>
        ))}
      </div>
    </section>
  );
};
