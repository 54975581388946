import { Mail } from "lucide-react"; // Lucide icon import for the email

export const Footer = () => {
  return (
    <footer id="footer">
      <hr className="w-11/12 mx-auto" />

      <section className="container py-20 grid grid-cols-1 md:grid-cols-3 gap-x-12 gap-y-8">
        <div className="flex flex-col">
          <a
            rel="noreferrer noopener"
            href="/"
            className="font-bold text-xl flex items-center"
          >
            <img src="/adanthro-logo.png" alt="AdAnthro Logo" className="h-8 mr-2" />
          </a>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="font-bold text-lg">Resources</h3>
          <a
            rel="noreferrer noopener"
            href="#faq"
            className="opacity-60 hover:opacity-100"
          >
            FAQ
          </a>
          <a
            rel="noreferrer noopener"
            href="/blog"
            className="opacity-60 hover:opacity-100"
          >
            Blog
          </a>
          <a
            rel="noreferrer noopener"
            href="/privacy-policy"
            className="opacity-60 hover:opacity-100"
          >
            Privacy Policy
          </a>
          <a
            rel="noreferrer noopener"
            href="/terms-of-service"
            className="opacity-60 hover:opacity-100"
          >
            Terms of Service
          </a>
        </div>

        <div className="flex flex-col items-center md:items-end">
          <div className="flex items-center mb-4">
            <Mail className="mr-2 h-5 w-5" />
            <a href="mailto:hello@adanthro.com" className="text-primary hover:underline">
              hello@adanthro.com
            </a>
          </div>

          <p className="opacity-60 text-center md:text-right">
            &copy; 2024 AdAnthro. All rights reserved.
          </p>
        </div>
      </section>
    </footer>
  );
};
