import React, { useState, useEffect } from 'react';
import { Home, Link, User, ChevronLeft, ChevronRight, Clock } from 'lucide-react';
import AnalysisHistory from './AnalysisHistory';

const NavigationBar = ({ selectedAccount }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const toggleCollapse = () => {
    setIsTransitioning(true);
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => {
        setIsTransitioning(false);
      }, 300); // Duration of the transition in milliseconds
      return () => clearTimeout(timer);
    }
  }, [isCollapsed, isTransitioning]);

  return (
    <aside className={`flex flex-col ${isCollapsed ? 'w-20' : 'w-64'} h-full bg-white border-r border-gray-200 p-4 transition-all duration-300 relative`}>
      <button
        onClick={toggleCollapse}
        className="absolute top-2 right-0 transform p-2 hover:bg-gray-100 z-10"
      >
        {isCollapsed ? <ChevronRight className="h-5 w-5" /> : <ChevronLeft className="h-5 w-5" />}
      </button>
      <div className="flex items-center justify-center">
        <img
          src={isCollapsed && !isTransitioning ? "/a-icon.png" : "/adanthro-logo.png"}
          alt="Logo"
          className={`m-4 ${isCollapsed && !isTransitioning ? 'w-8' : 'w-50'}`}
        />
      </div>
      <div className="flex flex-col flex-grow overflow-hidden">
        <ul className="flex flex-col space-y-2">
          <li>
            <a href="/" className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 p-2 rounded-md">
              <Home className="h-5 w-5" />
              {!isCollapsed && <span>Dashboard</span>}
            </a>
          </li>
          <li>
            <a href="https://api.adanthro.ai/start-auth" className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 p-2 rounded-md">
              <Link className="h-5 w-5" />
              {!isCollapsed && <span>Connect to Google Ads</span>}
            </a>
          </li>
          <li>
            <a href="/automated-reports" className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 p-2 rounded-md mb-4">
              <Clock className="h-5 w-5" />
              {!isCollapsed && <span>Automated Reports</span>}
            </a>
          </li>
        </ul>
        <div className="flex-grow flex flex-col justify-starts border-t border-gray-200 pt-4 overflow-y-auto">
          {!isCollapsed && <AnalysisHistory selectedAccount={selectedAccount} />}
        </div>
      </div>
    </aside>
  );
};

export default NavigationBar;
