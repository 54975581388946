/* global Paddle */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import Header from './Header';
import Button from './ui/Button';
import ConfirmationModal from './ConfirmationModal';

const SubscriptionManagementPage = () => {
  const [subscription, setSubscription] = useState(null);
  const [successMessage, setSuccessMessage] = useState(''); // New state variable
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const navigate = useNavigate();

  // Load Paddle.js script and initialize it
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      Paddle.Environment.set('sandbox');
      Paddle.Initialize({
        token: 'test_c43fc901fd4d083d63dff9d97db',
      });
    };

    // Clean up script on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Fetch subscription details
  const fetchSubscription = async () => {
    try {
      const response = await fetch('https://api.adanthro.ai/api/get-subscription', {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch subscription details');
      }
      const data = await response.json();
      console.log('Subscription data:', data);  // Verify data structure
      setSubscription(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, []);

  const handleCancelSubscription = async () => {
    setError('');
    try {
      setLoading(true);
      // Call the backend API to cancel the subscription
      const response = await fetch('https://api.adanthro.ai/api/cancel-subscription', {
        method: 'POST',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }
      const data = await response.json();
      setShowCancelModal(false);
      setSuccessMessage(data.message); // Set the success message
      fetchSubscription(); // Refresh the subscription data
    } catch (err) {
      setError(`Error canceling subscription: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenCancelModal = () => {
    setShowCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleChangePlan = () => {
    navigate('/plans', { state: { from: 'change' } });
  };

  const handleStartSubscription = () => {
    navigate('/plans', { state: { from: 'start' } });
  };

  const handleUpdatePaymentMethod = async () => {
    try {
      setLoading(true);

      const response = await fetch('https://api.adanthro.ai/api/update-payment-method', {
        method: 'GET',
        credentials: 'include',
      });

      const data = await response.json();
      console.log('Payment method update response:', data.transaction_id);

      if (response.ok && data.transaction_id) {
        // Construct the URL with the transaction ID
        const checkoutUrl = `https://adanthro.ai/my-plan?_ptxn=${data.transaction_id}`;

        // Directly navigate to the checkout URL
        window.location.href = checkoutUrl;

      } else {
        throw new Error(data.error || 'Failed to retrieve payment update link');
      }
    } catch (err) {
      setError(`Error updating payment method: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const getReadableStatus = (status) => {
    if (!status) return 'Unknown';
    const lowerStatus = status.toLowerCase();
    switch (lowerStatus) {
      case 'active':
        return 'Active';
      case 'canceled':
        return 'Canceled';
      case 'trialing':
        return 'Trialing';
      case 'paused':
        return 'Paused';
      case 'expired':
        return 'Expired';
      case 'past_due':
        return 'Past Due';
      default:
        return status;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner" />
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <NavigationBar />
      <div className="flex-grow flex flex-col h-full p-4">
        <Header />

        <h2 className="text-2xl font-semibold mb-4">Subscription Management</h2>

        {error && <p className="text-red-500 mb-4">{error}</p>}
        {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

        <div className="p-4 bg-white rounded-lg shadow h-full border border-gray-200">
          {subscription && subscription.message ? (
            // No active subscription
            <>
              <p>{subscription.message}</p>
              <Button onClick={handleStartSubscription} className="bg-green-500 hover:bg-green-700 text-white mt-4 w-auto">
                Start Subscription
              </Button>
            </>
          ) : subscription && (subscription.status && (subscription.status.toLowerCase() === 'active' || subscription.status.toLowerCase() === 'trialing')) ? (
            // Active or Trialing subscription
            <div>
              <h3 className="text-lg font-semibold mb-2">Current Plan: {subscription.plan_name || 'N/A'}</h3>
              <p>Status: {getReadableStatus(subscription.status)}</p>

              {subscription.end_date ? (
                <>
                  {/* If there's an end date, show 'Available Until' with the end date */}
                  <p>Available Until: {new Date(subscription.end_date).toLocaleDateString()}</p>
                  <p>Next Billing: No future payments scheduled.</p>
                </>
              ) : (
                <>
                  {/* If there's no end date, show 'Next Billing Date' */}
                  <p>
                    Next Billing Date: {subscription.next_billing_date ? new Date(subscription.next_billing_date).toLocaleDateString() : 'N/A'}
                  </p>
                </>
              )}

              {/* Only show the buttons if there's no end date */}
              {!subscription.end_date && (
                <>
                  {subscription.status.toLowerCase() !== 'trialing' && (
                    <div className="mt-4">
                      <h4 className="font-semibold mb-2">Change Plan:</h4>
                      <Button onClick={handleChangePlan}>Change Plan</Button>
                    </div>
                  )}

                  <div className="mt-4">
                    <h4 className="font-semibold mb-2">Actions:</h4>
                    <Button onClick={handleUpdatePaymentMethod}>Update Payment Method</Button>
                    <Button onClick={handleOpenCancelModal} className="bg-red-500 hover:bg-red-700 text-white ml-4">Cancel Subscription</Button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              <p>Unexpected subscription status.</p>
              <Button onClick={handleStartSubscription} className="bg-green-500 hover:bg-green-700 text-white mt-4 w-auto">
                Start Subscription
              </Button>
            </>
          )}

          {showCancelModal && (
            <ConfirmationModal
              title="Cancel Subscription"
              message="Are you sure you want to cancel your subscription? If you cancel your subscription, you will be able to use your remaining plan until the end of your current billing cycle. Then you will need to choose a new plan to continue using your service."
              confirmButtonText="Yes, Cancel"
              onClose={handleCloseCancelModal}
              onConfirm={handleCancelSubscription}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionManagementPage;
