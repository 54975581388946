import { Button } from "./ui/button";

export const Cta = () => {
  return (
    <section id="cta" className="bg-gray-100 py-16 my-24 sm:my-32">
      <div className="container lg:grid lg:grid-cols-2 place-items-center">
        <div className="lg:col-start-1">
          <h2 className="text-3xl md:text-4xl font-bold">
            Supercharge Your Google Ads Campaigns with
            <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">
              {" "}AI-Powered Insights
            </span>
          </h2>
          <p className="text-muted-foreground text-xl mt-4 mb-8 lg:mb-0">
            Maximize your advertising ROI with personalized recommendations, automated reporting, and comprehensive analytics. Transform your marketing strategy with our cutting-edge platform.
          </p>
        </div>

        <div className="space-y-4 lg:col-start-2">
          <Button className="w-full md:mr-4 md:w-auto">Start Now</Button>
          <Button variant="outline" className="w-full md:w-auto">
            Explore Features
          </Button>
        </div>
      </div>
    </section>
  );
};
