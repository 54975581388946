import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const AutomatedReportsSection = ({ selectedAccount }) => {
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedAccount && selectedAccount.id) {
      fetchAutomatedReports();
    }
  }, [selectedAccount]);

  const fetchAutomatedReports = async () => {
    if (!selectedAccount || !selectedAccount.id) {
      console.log('No account selected');
      return;
    }

    try {
      const response = await fetch(`https://api.adanthro.ai/api/get-automated-reports?accountId=${selectedAccount.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setReports(data);
    } catch (error) {
      console.error('Error fetching automated reports:', error);
    }
  };

  const handleSeeAll = () => {
    navigate('/automated-reports');
  };

  return (
    <div className="mb-4 p-4 bg-white rounded-lg shadow border border-gray-200">
      <h3 className="font-semibold mb-2">Automated Reports</h3>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Frequency</th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Last Run</th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Result</th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Status</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {reports.slice(0, 3).map((report) => ( // Show only the first 3 reports
            <tr key={report.id}>
              <td className="px-6 py-4 whitespace-nowrap">{report.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{report.schedule_frequency}</td>
              <td className="px-6 py-4 whitespace-nowrap">{report.last_run ? new Date(report.last_run).toLocaleString() : 'Never'}</td>
              <td className="px-6 py-4 whitespace-nowrap">{report.result || 'N/A'}</td>
              <td className="px-6 py-4 whitespace-nowrap">{report.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-end mt-4">
        <button
          className="text-black hover:bg-gray-800 font-semibold"
          onClick={handleSeeAll}
        >
          See All
        </button>
      </div>
    </div>
  );
};

export default AutomatedReportsSection;
