import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import DashboardPage from './components/main-app/DashboardPage';
import LoginPage from './components/main-app/LoginPage';
import RegisterPage from './components/main-app/RegisterPage';
import PlansPage from './components/main-app/PlansPage';  
import ProtectedRoute from './components/main-app/ProtectedRoute';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import { AccountProvider } from './contexts/AccountContext';
import './tailwind.css';
import './App.css';
import AnalysisPage from './components/main-app/AnalysisPage';
import AutomatedReportsPage from './components/main-app/AutomatedReportsPage';
import VerifyEmailPage from './components/main-app/VerifyEmailPage';
import FutureOfMarketingPage from './components/main-app/FutureOfMarketingPage';
import SettingsPage from './components/main-app/SettingsPage';
import LandingPage from './components/landing-page/App';
import { TermsOfService } from './components/landing-page/components/Terms';
import { PrivacyPolicy } from './components/landing-page/components/Privacy';
import { Blog } from './components/landing-page/components/Blog';
import SubscriptionManagementPage from './components/main-app/SubscriptionManagementPage';

function App() {
  return (
    <AuthProvider>
      <AccountProvider>
        <Router>
          <MainApp />
        </Router>
      </AccountProvider>
    </AuthProvider>
  );
}

function MainApp() {
  const location = useLocation();
  const { isAuthenticated } = useContext(AuthContext);

  // Debugging output
  console.log('isAuthenticated:', isAuthenticated);
  console.log('location:', location.pathname);

  // Determine background color based on the route and login status
  const bgColor = isAuthenticated ? 'bg-gray-50' : 'bg-white';

  return (
    <div className={`${bgColor} min-h-screen`}>
      <Routes>
        <Route path="/" element={!isAuthenticated ? <LandingPage /> : <DashboardPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/plans" element={<PlansPage />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/future-of-marketing" element={<FutureOfMarketingPage />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/blog' element={<Blog />} />
        <Route
          path="/analysis/:id"
          element={
            <ProtectedRoute>
              <AnalysisPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/automated-reports"
          element={
            <ProtectedRoute>
              <AutomatedReportsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-plan"
          element={
            <ProtectedRoute>
              <SubscriptionManagementPage />
            </ProtectedRoute>
          }
        />
        {/* Fallback Route */}
        <Route path="*" element={<div>Page Not Found - Invalid Route</div>} />
      </Routes>
    </div>
  );
}

export default App;
