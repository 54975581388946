/* global Paddle */
import { useNavigate } from 'react-router-dom'; // Use useNavigate for React Router v6
import React, { useContext, useEffect } from 'react';
import Button from './ui/Button';

const FutureOfMarketingPage = () => {
    const navigate = useNavigate(); 

    useEffect(() => {

        fetch('https://api.adanthro.ai/api/get-paddle-customer-id', {
            method: 'GET',
            credentials: 'include', 
        })
        .then(response => response.json())
        .then(data => {
            if (data.paddleCustomerId) {
                navigate('/'); 
            }
        });

        const hash = window.location.hash.substring(1); // Removes the `#`
        const params = new URLSearchParams(hash);
        const accessToken = params.get('access_token');

        if (accessToken) {
            console.log('Access Token:', accessToken);

            // Send the token to your backend for login
            fetch('https://api.adanthro.ai/login-with-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ access_token: accessToken }),
                credentials: 'include', // Include cookies with the request
            })
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    console.log('Login successful:', data.message);
                    // Redirect or handle successful login
                } else {
                    console.error('Login failed:', data.error);
                }
            })
            .catch(error => console.error('Unexpected error:', error));
        }

        // Load Paddle.js script dynamically
        const script = document.createElement('script');
        script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
        script.async = true;
        document.body.appendChild(script);
    
        script.onload = () => {
            Paddle.Environment.set("sandbox");
            Paddle.Initialize({
                token: 'test_c43fc901fd4d083d63dff9d97db', // Replace with your actual client-side token
            });
        };
    
        script.onerror = () => {
            console.error('Failed to load Paddle script');
        };
    
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleContinue = () => {
        // Fetch a secure transaction token from the backend
        fetch('https://api.adanthro.ai/api/generate-transaction-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', 
        })
        .then(response => response.json())
        .then(data => {
            const { transactionToken, email } = data;

            if (window.Paddle) {
                window.Paddle.Checkout.open({
                    settings: {
                        successUrl: 'https://adanthro.ai/',
                    },
                    items: [{ priceId: 'pri_01j6zfhmhc0r8sam2kptkrcyzq' }],
                    customer: {
                        email: email,
                    },
                    customData: JSON.stringify({
                        transaction_token: transactionToken // Pass secure token to Paddle
                    }),
                    successCallback: (data) => {
                        console.log('Checkout complete:', data);
                        // Handle successful checkout
                    },
                    closeCallback: () => {
                        console.log('Checkout closed without purchase.');
                    }
                });
            } else {
                console.error('Paddle is not initialized');
            }
        })
        .catch(error => console.error('Error generating transaction token:', error));
    };


    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-2xl p-8 bg-white rounded-md shadow-md border border-gray-200">
                <h1 className="text-3xl font-bold text-center text-gray-900 mb-4">
                    You're One Step Closer to the Future of Autonomous Marketing
                </h1>
                <h2 className="text-xl font-semibold text-center text-gray-700 mb-6">
                    Get ready for a world where AI agents tirelessly manage your campaigns.
                </h2>
                <p className="text-gray-600 text-left mb-6">
                    AdAnthro is more than a tool; it’s a glimpse into the future of marketing. By starting your trial, you’re positioning yourself at the forefront of a new era. Today, you’ll harness advanced insights that bridge the gap between manual effort and a future where AI agents will manage your campaigns autonomously, with greater precision and consistency than any human ever could.
                </p>
                <p className="text-gray-600 text-left mb-6">
                    This is a pivotal moment in the evolution of marketing. As you engage with AdAnthro, you are not just optimizing your current strategies; you are laying the groundwork for a future where intelligent systems take the reins, driving your campaigns with tireless efficiency. The actions you take now will prepare you for a world where marketing autonomy is not just a possibility, but an inevitability.
                </p>
                <p className="text-gray-600 text-left mb-6">
                    Your journey with AdAnthro marks the beginning of this transformation. The tools and insights you access today are the precursors to a fully autonomous future, one where AI-driven agents will seamlessly manage every aspect of your marketing, allowing you to focus on the bigger picture. This trial is your gateway to that future—an opportunity to experience firsthand the transition from human-driven processes to a new standard of precision and scalability.
                </p>
                <p className="text-gray-600 text-left mb-6">
                    Embrace this moment. By choosing AdAnthro, you are not just adapting to the present; you are preparing for the future. 
                </p>                
                <p className="text-gray-600 text-left mb-6">
                    Welcome to the next chapter in marketing.
                </p>
                <p className="text-gray-600 text-center mb-6">
                    Your 7-day trial is the start of something bigger...
                </p>
                <div className="flex justify-center">
                    <Button onClick={handleContinue} className="text-white py-2 px-6 rounded-lg transition-colors">
                        Trial the Future
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FutureOfMarketingPage;
