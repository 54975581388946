import React from "react";
import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import { cva } from "class-variance-authority";
import { ChevronDown } from "lucide-react";
import { cn } from '../../lib/utils';

// NavigationMenu Component
const NavigationMenu = React.forwardRef(
  (props, ref) => {
    const { className, children, ...restProps } = props;

    return (
      <NavigationMenuPrimitive.Root
        ref={ref}
        className={cn(
          "relative z-10 flex max-w-max flex-1 items-center justify-center",
          className
        )}
        {...restProps}
      >
        {children}
        <NavigationMenuViewport />
      </NavigationMenuPrimitive.Root>
    );
  }
);
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;

// NavigationMenuList Component
const NavigationMenuList = React.forwardRef(
  (props, ref) => {
    const { className, ...restProps } = props;

    return (
      <NavigationMenuPrimitive.List
        ref={ref}
        className={cn(
          "group flex flex-1 list-none items-center justify-center space-x-1",
          className
        )}
        {...restProps}
      />
    );
  }
);
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;

// NavigationMenuItem Component
const NavigationMenuItem = NavigationMenuPrimitive.Item;

// NavigationMenuTrigger Styles
const navigationMenuTriggerStyle = cva(
  "group inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50"
);

// NavigationMenuTrigger Component
const NavigationMenuTrigger = React.forwardRef(
  (props, ref) => {
    const { className, children, ...restProps } = props;

    return (
      <NavigationMenuPrimitive.Trigger
        ref={ref}
        className={cn(navigationMenuTriggerStyle(), "group", className)}
        {...restProps}
      >
        {children}
        <ChevronDown
          className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
          aria-hidden="true"
        />
      </NavigationMenuPrimitive.Trigger>
    );
  }
);
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;

// NavigationMenuContent Component
const NavigationMenuContent = React.forwardRef(
  (props, ref) => {
    const { className, ...restProps } = props;

    return (
      <NavigationMenuPrimitive.Content
        ref={ref}
        className={cn(
          "left-0 top-0 w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 md:absolute md:w-auto",
          className
        )}
        {...restProps}
      />
    );
  }
);
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;

// NavigationMenuLink Component
const NavigationMenuLink = NavigationMenuPrimitive.Link;

// NavigationMenuViewport Component
const NavigationMenuViewport = React.forwardRef(
  (props, ref) => {
    const { className, ...restProps } = props;

    return (
      <div className={cn("absolute left-0 top-full flex justify-center")}>
        <NavigationMenuPrimitive.Viewport
          className={cn(
            "origin-top-center relative mt-1.5 h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90 md:w-[var(--radix-navigation-menu-viewport-width)]",
            className
          )}
          ref={ref}
          {...restProps}
        />
      </div>
    );
  }
);
NavigationMenuViewport.displayName =
  NavigationMenuPrimitive.Viewport.displayName;

// NavigationMenuIndicator Component
const NavigationMenuIndicator = React.forwardRef(
  (props, ref) => {
    const { className, ...restProps } = props;

    return (
      <NavigationMenuPrimitive.Indicator
        ref={ref}
        className={cn(
          "top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in",
          className
        )}
        {...restProps}
      >
        <div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-border shadow-md" />
      </NavigationMenuPrimitive.Indicator>
    );
  }
);
NavigationMenuIndicator.displayName =
  NavigationMenuPrimitive.Indicator.displayName;

export {
  navigationMenuTriggerStyle,
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuContent,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuIndicator,
  NavigationMenuViewport,
};
