import React from 'react';
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";

export const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <section id="privacy-policy" className="container py-24 sm:py-32 space-y-8">
        <h2 className="text-3xl lg:text-4xl font-bold md:text-center">
          Privacy Policy
        </h2>

        <div className="space-y-8">
          <div>
            <h3 className="text-2xl font-bold">Introduction</h3>
            <p className="mt-4">
              At AdAnthro, we are committed to protecting the privacy and confidentiality of your personal and business information. This Privacy Policy explains the data we collect, how it is used, stored, and protected, and the limited circumstances under which it may be shared.
            </p>
          </div>

          <div>
            <h3 className="text-2xl font-bold">Information We Collect</h3>
            <p className="mt-4">
              We collect the following types of information to provide you with marketing insights and campaign analysis:
            </p>
            <ul className="list-disc pl-5 mt-4">
              <li><strong>Personal Information:</strong> Your name, email address, and account details for managing your subscription.</li>
              <li><strong>Google Ads Data:</strong> Campaign performance data such as clicks, impressions, and costs.</li>
              <li><strong>Chat Interactions:</strong> Your interactions with the AI, including inputs and queries.</li>
              <li><strong>Campaign Data:</strong> Information related to marketing campaigns, goals, competitor details, and strategies.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-2xl font-bold">How We Use Your Information</h3>
            <p className="mt-4">
              Your data is collected to deliver our services, including:
            </p>
            <ul className="list-disc pl-5 mt-4">
              <li>Generating Google Ads insights and analysis.</li>
              <li>Providing personalized AI recommendations and reports.</li>
              <li>Managing your subscription and access to features within AdAnthro.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-2xl font-bold">Data Sharing and Retention</h3>
            <p className="mt-4">
              We do <strong>not</strong> share your ads data, chat interactions, company information, or any other personal or business details with third-party AI providers or external companies for purposes beyond what is necessary to deliver and maintain our services.
            </p>
            <p className="mt-4">
              Specifically, none of this data is sold, shared, or disclosed for marketing, data mining, or other commercial purposes unrelated to the direct delivery of our services. This applies to all data processed through AdAnthro, including chat interactions with the AI, inputs for campaign analysis, and AI-generated outputs.
            </p>
            <p className="mt-4">
              Data processed through OpenAI's API, which powers the AI features of AdAnthro, is <strong>not</strong> used to train OpenAI models. OpenAI retains API data for up to 30 days for the purposes of identifying abuse, after which it is deleted unless required by law.
            </p>
          </div>

          <div>
            <h3 className="text-2xl font-bold">Data Security</h3>
            <p className="mt-4">
              We take security seriously and use encryption and industry-standard measures to protect your data from unauthorized access. While we strive to secure your information, no transmission method is 100% secure, and we cannot guarantee absolute protection.
            </p>
          </div>

          <div>
            <h3 className="text-2xl font-bold">Your Rights and Choices</h3>
            <p className="mt-4">
              You have control over your data. You can:
            </p>
            <ul className="list-disc pl-5 mt-4">
              <li>Access and update your personal information through your account settings.</li>
              <li>Request the deletion of your data by contacting us at <a href="mailto:legal@adanthro.ai" className="text-primary hover:underline">legal@adanthro.ai</a>.</li>
              <li>Disconnect your Google Ads account at any time to clear related data.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-2xl font-bold">International Data Transfers</h3>
            <p className="mt-4">
              If you are located outside of the United States, your data may be transferred to and processed in a different country. We ensure appropriate safeguards are in place to protect your data in such cases.
            </p>
          </div>

          <div>
            <h3 className="text-2xl font-bold">Changes to This Privacy Policy</h3>
            <p className="mt-4">
              We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. Updates will be posted on this page, and we encourage you to review the policy regularly.
            </p>
          </div>

          <div>
            <h3 className="text-2xl font-bold">Contact Information</h3>
            <p className="mt-4">
              If you have any questions about this Privacy Policy, please contact us at <a href="mailto:legal@adanthro.ai" className="text-primary hover:underline">legal@adanthro.ai</a>.
            </p>
          </div>

        </div>
      </section>
      <Footer />
    </>
  );
};
