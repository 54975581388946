import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Bars } from 'react-loader-spinner';
import { ArrowUp, ArrowDown, ArrowRight } from 'lucide-react';
import { format } from 'date-fns';

const colorMap = ['#6929c4', '#9f1853', '#198038', '#b28600'];

const AccountDataVisualization = ({ data, comparisonData, currencyCode }) => {
  const mainMetrics = ['Cost', 'Impressions', 'Clicks', 'Conversions'];
  const [activeMetrics, setActiveMetrics] = useState(mainMetrics.reduce((acc, metric) => ({ ...acc, [metric]: true }), {}));

  useEffect(() => {
    console.log('Account Data:', data);
    console.log('Comparison Data:', comparisonData);
    console.log('Currency Code:', currencyCode); // Log the currency code
  }, [data, comparisonData, currencyCode]);

  if (!Array.isArray(data) || data.length === 0) {
    return (
      <div className="flex justify-center items-center h-full">
        <Bars color="#195afe" height={80} width={80} />
      </div>
    );
  }

  const toggleMetric = (metric) => {
    setActiveMetrics((prev) => ({ ...prev, [metric]: !prev[metric] }));
  };

  const combinedData = data.map((item, index) => {
    const comparisonItem = comparisonData ? comparisonData[index] || {} : {};
    return {
      ...item,
      comparisonCost: comparisonItem.Cost,
      comparisonImpressions: comparisonItem.Impressions,
      comparisonClicks: comparisonItem.Clicks,
      comparisonConversions: comparisonItem.Conversions,
      comparisonDate: comparisonItem.Date
    };
  });

  const calculateTotalAndDifference = (metric) => {
    const total = data.reduce((sum, row) => sum + parseFloat(row[metric]), 0);
    const comparisonTotal = comparisonData ? comparisonData.reduce((sum, row) => sum + parseFloat(row[metric]), 0) : 0;
    const difference = total - comparisonTotal;
    return { total, difference };
  };

  const formatDifference = (metric, value) => {
    if (metric === 'Cost') {
      return `${value.toFixed(2)} ${currencyCode}`;
    }
    return `${value.toLocaleString()}`;
  };

  const tooltipStyles = {
    backgroundColor: '#ffffff',
    border: '0px',
    borderRadius: '5px',
    padding: '10px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    fontSize: '14px',
    color: '#333333',
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = format(new Date(label), 'EEEE, MMMM d');
      const hasComparisonData = payload[0]?.payload?.comparisonDate != null;

      return (
        <div style={{ ...tooltipStyles, width: '300px' }}>
          {!hasComparisonData && <p className="font-bold mb-2">{date}</p>}
          {mainMetrics.map((metric, index) => {
            const value = payload[0]?.payload[metric];
            const comparisonValue = payload[0]?.payload[`comparison${metric}`];
            const difference = value - (comparisonValue || 0);
            const arrowIcon = difference > 0 ? <ArrowUp size={16} /> : difference < 0 ? <ArrowDown size={16} /> : <ArrowRight size={16} />;
            const textColor = colorMap[index % colorMap.length];

            if (hasComparisonData) {
              const comparisonDate = format(new Date(payload[0].payload.comparisonDate), 'EEEE, MMMM d');
              return (
                <div key={metric} style={{ marginBottom: '10px', color: textColor }}>
                  <div className="flex items-center justify-between">
                    <p><strong>{metric}</strong></p>
                    <span className="flex items-center">
                      {arrowIcon}
                      <span className="ml-1">{formatDifference(metric, Math.abs(difference))}</span>
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <p>{date}</p>
                    <p>{value !== null ? formatDifference(metric, value) : '-'}</p>
                  </div>
                  <div className="flex justify-between">
                    <p>{comparisonDate}</p>
                    <p>{comparisonValue !== null ? formatDifference(metric, comparisonValue) : '-'}</p>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={metric} className="flex justify-between" style={{ marginBottom: '5px', color: textColor }}>
                  <p><strong>{metric}</strong></p>
                  <p>{value !== null ? formatDifference(metric, value) : '-'}</p>
                </div>
              );
            }
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow h-full border border-gray-200">
      <h2 className="font-semibold mb-2">Account Data</h2>
      <div className="flex flex-row">
        <div className="flex flex-col space-y-2 p-2">
          {mainMetrics.map((metric, index) => {
            const isActive = activeMetrics[metric];
            const textColor = colorMap[index % colorMap.length];
            const { total, difference } = calculateTotalAndDifference(metric);

            return (
              <div
                key={metric}
                onClick={() => toggleMetric(metric)}
                className={`px-4 py-1 border border-gray-300 rounded-md cursor-pointer ${isActive ? '' : 'bg-gray-50'}`}
                style={{ color: isActive ? textColor : 'gray' }}
              >
                <p className="mb-1">{metric}</p>
                <div className="flex items-center justify-between">
                  <p className="font-bold">
                    {
                      metric === 'Cost'
                        ? total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' + currencyCode
                        : total.toLocaleString()
                    }
                  </p>
                  <div className="flex items-center" style={{ color: isActive ? textColor : 'gray' }}>
                    {difference > 0 ? (
                      <ArrowUp size={16} className="mr-1" />
                    ) : (
                      <ArrowDown size={16} className="mr-1" />
                    )}
                    <span className="text-sm">
                      {formatDifference(metric, Math.abs(difference))}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex-1 ml-4">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={combinedData}>
              <CartesianGrid vertical={false} strokeWidth={0.5} />
              <XAxis dataKey="Date" />
              <Tooltip content={<CustomTooltip />} />
              {mainMetrics.map((metric, index) => (
                <React.Fragment key={metric}>
                  <YAxis yAxisId={metric} hide={true} />
                  {activeMetrics[metric] && (
                    <>
                      <Line
                        dataKey={metric}
                        stroke={colorMap[index % colorMap.length]}
                        strokeWidth={2}
                        dot={false}
                        yAxisId={metric}
                        name={metric}
                      />
                      {comparisonData && comparisonData.length > 0 && (
                        <Line
                          dataKey={`comparison${metric}`}
                          stroke={colorMap[index % colorMap.length]}
                          strokeWidth={2}
                          dot={false}
                          yAxisId={metric}
                          name={`comparison${metric}`}
                          strokeDasharray="5 5"
                        />
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default AccountDataVisualization;
